import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { FormikTextfield } from "../../../components/FormikTextfield"

const CreateAccount = (props) => {
  const { booking, setBooking, handleUserDetailsStep, handleAccountSubmit } = props
  const [showPass, setShowPass] = useState(false)

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ fontSize: "15px" }} />
      <div class="section" id="section5">
        <Formik
          initialValues={{
            name: "",
            mobile: "",
            authemail: "",
            authpassword: "",
            address1: "",
            address2: "",
            state: "Alabama",
            zipcode: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
            mobile: Yup.number().integer("Must be Number").positive().min(10, "Must be 10 digits").nullable().required("Required"),
            authemail: Yup.string().email("Invalid Email Address").required("Required"),
            // authpassword: Yup.string().min(10, "Atleast 10 character").required("Required"),
            authpassword: Yup.string()
              .matches(
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              )
              .required(" "),
            address1: Yup.string().required("Required"),
            // address2: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            zipcode: Yup.string().min(5, "Must be 5 digits").required("Required"),
          })}
          // onSubmit={(values, { setSubmitting }) => {
          //     setBooking({ ...booking, customer: values })
          //     handleUserDetailsStep(3)
          //     //  setTimeout(() => {
          //     //    alert(JSON.stringify(values, null, 2));
          //     //    setSubmitting(false);
          //     //  }, 400);
          // }}
          onSubmit={(values, { setSubmitting }) => {
            handleAccountSubmit(values)
          }}>
          {({ values, setFieldValue, errors, touched, ...rest }) => {
            return (
              <Form>
                <div class="content">
                  <p class="section-title B TT">
                    <a onClick={() => handleUserDetailsStep(1)}>
                      <span class="fa fa-arrow-left"></span>
                    </a>{" "}
                    Create your account with us{" "}
                  </p>
                  <div class="reg-form">
                    <div class="form-field">
                      <div class="field half">
                        <label>Name *</label>
                        <Field name="name" component={FormikTextfield} type="text" placeholder="First Last Name" />
                      </div>
                      <div class="field half last" style={{ marginLeft: "1em", width: "46%" }}>
                        <label>Mobile Number*</label>
                        <Field
                          name="mobile"
                          component={FormikTextfield}
                          type="number"
                          placeholder="_ _ _-_ _ _-_ _ _ _"
                          onChange={(e) => {
                            let valid = /^\d{0,10}$/.test(e.target.value)
                            valid && setFieldValue("mobile", e.target.value)
                          }}
                        />
                      </div>
                    </div>

                    <div class="form-field">
                      <div class="field">
                        <label for="emailaddress">Email *</label>
                        <Field name="authemail" component={FormikTextfield} type="text" placeholder="example@email.com" />
                      </div>
                    </div>

                    <div class="form-field">
                      <div class="field position-relative">
                        <label>Password *</label>
                        <Field type={showPass ? "text" : "password"} name="authpassword" component={FormikTextfield} />
                        <div className="passEyeIcon">
                          <i className={`fa ${showPass ? "fa-eye" : "fa-eye-slash"}`} role="button" onClick={() => setShowPass(!showPass)}></i>
                        </div>
                        {errors["authpassword"] && <small style={{ color: "red", fontSize: ".6em" }}>{errors["authpassword"]}</small>}
                      </div>
                    </div>

                    <div class="form-field address">
                      <div class="field">
                        <label>Address *</label>
                        <Field name="address1" component={FormikTextfield} type="text" placeholder="Street Address" />
                      </div>
                    </div>

                    <div class="form-field unit-state">
                      <div class="field half">
                        <div class="form-field suite">
                          <label>Unit / Suite / Apartment #</label>
                          <Field name="address2" component={FormikTextfield} type="text" />
                        </div>
                      </div>
                      <div class="field half state">
                        <div class="form-select">
                          <label>State</label>
                          <select
                            name="state"
                            value={values.state}
                            onChange={(e) => {
                              setFieldValue("state", e.target.value)
                            }}
                            style={{ ...(touched["state"] && errors["state"] && { border: "solid red 1px" }) }}>
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Delaware</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option>
                            <option>New Jersey</option>
                            <option>New Mexico</option>
                            <option>New York</option>
                            <option>North Carolina</option>
                            <option>North Dakota</option>
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option>
                            <option>South Carolina</option>
                            <option>South Dakota</option>
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option>
                            <option>Wisconsin</option>
                            <option>Wyoming</option>
                          </select>
                        </div>

                        {touched["state"] && errors["state"] && (
                          <div style={{ fontSize: ".7em", color: "red", padding: ".3em 0 0 1em" }}>{errors["state"]}</div>
                        )}
                      </div>
                      <div class="field half zip" style={{ marginLeft: ".3em" }}>
                        <div class="form-field">
                          <label>Zip Code</label>
                          <Field
                            name="zipcode"
                            component={FormikTextfield}
                            type="number"
                            // maxLength={"5"}
                            onChange={(e) => {
                              let valid = /^\d{0,5}$/.test(e.target.value)
                              valid && setFieldValue("zipcode", e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <small>We'll send you an email to confirm your appointment and to provide you with other updates, if needed.</small>
                    </div>
                    {/* <a
                              class='button continuebtn'
                              href='#seventhSection'
                            >
                              Continue{' '}
                              <span class='fa fa-arrow-circle-o-right'></span>
                            </a> */}
                    <button
                      class={!(rest.isValid && rest.dirty) ? "button continuebtn" : "button continuebtn-active"}
                      type="submit"
                      style={{ border: "0" }}>
                      Continue <span class="fa fa-arrow-circle-o-right"></span>
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateAccount
