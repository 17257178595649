import React, { useState, useEffect } from "react"
import api, { toFormData } from "../libs/api"
import { handleApiError } from "../utils/handleApiError"
import { shiftTzDateToPickerDate } from "../utils/dateUtils"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { computeHeadingLevel } from "@testing-library/react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ConfirmBooking2 from "./ConfirmBooking2"
import { upload } from "@testing-library/user-event/dist/upload"

import logoImg from "../assets/images/igd-logo.png"

import gImg from "../assets/images/1.PNG"
import l1Img from "../assets/images/l1.PNG"
import l2Img from "../assets/images/l2.PNG"
import l3Img from "../assets/images/l3.PNG"
import l4Img from "../assets/images/l4.jpg"
import l5Img from "../assets/images/l5.png"
import l6Img from "../assets/images/l6.png"
import "../assets/styles/shop-product.css"

const ShopProducts = (props) => {
  const { shopProducts, setShopProducts, products, setProducts } = props
  const [prodModal, setProdModal] = useState(null)
  const [searchProd, setSearchProd] = useState(shopProducts?.products ?? [])

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  return (
    <>
      <div class={`overlay02 is-open`}>
        <section class="container">
          {/* <ToastContainer
                position='bottom-right'
                theme='colored'
                style={{ fontSize: '15px' }}
            //   autoClose={5000}
            //   hideProgressBar={false}
            //   newestOnTop={false}
            //   closeOnClick
            //   rtl={false}
            //   pauseOnFocusLoss
            //   draggable
            //   pauseOnHover 
            /> */}
          <a href="#" class="fa fa-times close-btn" onClick={() => setShopProducts({ open: false })}></a>
          <div class="igdHeader">
            <img class="headerLogo" src={logoImg} alt="" />
            <p class="headerTitle">Find the best product for your plumbing and HVAC needs!</p>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="sidebar">
                <div class="search">
                  <div class="form-group">
                    <input
                      type="input"
                      class="form-control"
                      id="search"
                      placeholder="Search Product..."
                      onChange={(e) => {
                        if (e.target.value)
                          setSearchProd(shopProducts?.products?.filter((item) => item.headertext.toLowerCase().includes(e.target.value)))
                        else setSearchProd(shopProducts?.products)
                      }}
                    />
                    <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                  </div>
                </div>

                <div class="sidebarTitle">Category: </div>
                <span class="sidebarTitle">{shopProducts.category}</span>

                {/* <div class="panel-group productcat" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading section-heading" role="tab" id="headingOne">
                                        <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <a role="button">Plumbing <span class="glyphicon glyphicon-menu-down"></span></a>
                                        </h4>
                                    </div>
                                    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                        <div class="panel-body">
                                            <ul class="productList">
                                                <li>Faucet</li>
                                                <li>Garbage Disposal</li>
                                                <li>Toilet</li>
                                                <li>water Heater</li>
                                                <li>Tankless Water Heater</li>
                                                <li>Thermostats</li>
                                                <li>Air Conditioners</li>
                                                <li>Heat Pumps</li>
                                                <li>Furnaces</li>
                                                <li>Hydronics</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default ">
                                    <div class="panel-heading section-heading" role="tab" id="headingTwo">
                                        <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <a role="button">HVAC <span class="glyphicon glyphicon-menu-down"></span></a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                        <div class="panel-body">
                                            <ul class="productList">
                                                <li>Thermostats</li>
                                                <li>Air Conditioners</li>
                                                <li>Heat Pumps</li>
                                                <li>Furnaces</li>
                                                <li>Hydronics</li>
                                                <li>Faucet</li>
                                                <li>Garbage Disposal</li>
                                                <li>Toilet</li>
                                                <li>water Heater</li>
                                                <li>Tankless Water Heater</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
            <div class="col-md-9 mt-2">
              <div class="row">
                {/* <div class="col-md-12 col-sm-12">
                  <select class="form-control sortBy">
                    <option>Sort by</option>
                    <option>Popular</option>
                    <option>Low to High</option>
                    <option>High to Low</option>
                  </select>
                </div> */}
                <div class="col-md-12 col-sm-12">
                  <div class="row" style={{ marginTop: "25px" }}>
                    {searchProd?.map((pItem) => (
                      <>
                        <div key={pItem.id} class="col-md-4 col-sm-6 col-xs-12">
                          <div class="productCard">
                            <div class="productImg">
                              {pItem.files ? (
                                <img src={pItem.files[0]?.valueUrl} alt={pItem.files[0]?.value} />
                              ) : (
                                <img alt={`No Image Available`} style={{ height: "100%" }} />
                              )}
                            </div>
                            <div class="productDiscription">
                              <span class="producctTitle">{pItem.headertext}</span>
                              <span class="productdisc">{pItem.subheadertext}</span>
                              {/* <span class="price">${pItem.productprices}</span> */}
                            </div>
                            <div class="productPurchase">
                              <a
                                class="btn-primery"
                                href="#"
                                onClick={() => {
                                  shopProducts.handleBookProduct(pItem)
                                  setShopProducts({ open: false, data: {} })
                                }}>
                                Book Now
                              </a>
                              <span class="btn-sec" style={{ cursor: "pointer" }} onClick={() => setProdModal(pItem)}>
                                View Details
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="logoList">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <h1>Brands we specialize in</h1>
              </div>
              <div class="col-md-12">
                <div class="logos">
                  <img src={l1Img} />
                  <img src={l2Img} />
                  <img src={l3Img} />
                  <img src={l4Img} />
                  <img src={l5Img} />
                  <img src={l6Img} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <p>Copyrights IGD Plumbing & Air 2023. All Rights Reserved. Powered By K Business Solutions Inc.</p>
        </footer>

        <script
          src="https://code.jquery.com/jquery-1.12.4.min.js"
          integrity="sha384-nvAa0+6Qg9clwYCGGPpDQLVpLNn0fRaROjHqs13t4Ggj3Ez50XnGQqc/r8MhnRDZ"
          crossorigin="anonymous"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/js/bootstrap.min.js"
          integrity="sha384-aJ21OjlMXNL5UyIl/XNwTMqvzeRMZH2w8c5cRVpzpU8Y5bApTppSuUkhZXN0VxHd"
          crossorigin="anonymous"></script>
      </div>
      {prodModal && (
        <div class="view-product-modal" role="dialog" aria-labelledby="productDetailsLabel">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button type="button" class="close" aria-label="Close" onClick={() => setProdModal(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="productPopup">
                  <div class="productImgPopup">
                    {prodModal.files ? (
                      <img src={prodModal.files[0]?.valueUrl} alt={prodModal.files[0]?.value} />
                    ) : (
                      <img alt={`No Image Available`} style={{ height: "100%" }} />
                    )}
                  </div>
                  <div class="productDetailsPopup">
                    <div class="topDetails">
                      <h3 class="productTitlePopup">{prodModal.headertext}</h3>
                      <span class="producctSmallDisc">{prodModal.subheadertext}</span>
                      <small class="productCode">#{prodModal.productnumber}</small>
                    </div>
                    {/* <div class="ProductPrice">
                      <span>${prodModal.productprices}</span>
                    </div> */}
                    <div class="productWarranty">
                      {prodModal.itemWarrantyTerms && (
                        <>
                          <span>Item Warranty Terms :-</span>
                          <span> {prodModal.itemWarrantyTerms}</span>
                        </>
                      )}
                    </div>

                    {/* <div class="cartQuantity">
                      <span>Quantity</span>
                      <div class="cart-quantity">
                        <i class="fa fa-minus quantity-button" id="remove"></i>
                        <input
                          type="text"
                          class="quantiy-value"
                          id="quantity"
                          value="1"
                          min="1"
                          readonly
                        />
                        <i class="fa fa-plus quantity-button" id="add"></i>
                      </div>
                    </div> */}
                    <div class="buyPopupBtn">
                      <a
                        class="btn-primery"
                        href="#"
                        onClick={() => {
                          shopProducts.handleBookProduct(prodModal)
                          setShopProducts({ open: false, data: {} })
                        }}>
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
                <div class="productDetailsPopupDown">
                  {prodModal.description && (
                    <>
                      {" "}
                      <h3>Product Details</h3>
                      <p>{prodModal.description}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ShopProducts
