import React, { useState, useEffect } from "react"
import api, { toFormData } from "../../libs/api"
import { handleApiError } from "../../utils/handleApiError"
import { shiftTzDateToPickerDate } from "../../utils/dateUtils"
import * as Yup from "yup"
import { ToastContainer, toast } from "react-toastify"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { FormikTextfield } from "../../components/FormikTextfield"

const Profile = (props) => {
  const { booking } = props
  const profile = JSON.parse(localStorage.getItem("_authUser"))
  const [editProfile, setEditProfile] = useState(false)

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  const handleProfile = async (values) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${profile.authToken}`
      const { data } = await api.put(`v1/customers`, values)
      openToast({ color: "success", message: "Updated Successfully" })
      localStorage.setItem("_authUser", JSON.stringify({ ...profile, ...values }))
      setEditProfile(false)
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  return (
    <>
      <div class="profile-info col-md-9">
        <div class="panel">
          <div class="panel-body bio-graph-info">
            <h1 style={{ margin: 0 }}>
              Profile{" "}
              <span role="button" className="fa fa-edit h5 mx-5" style={{ color: "#34559A" }} onClick={() => setEditProfile(!editProfile)}></span>
            </h1>
            {!editProfile ? (
              <div style={{ marginTop: "2em" }}>
                {[
                  { label: "Name", value: profile?.name },
                  { label: "Email", value: profile?.authemail },
                  { label: "Phone ", value: profile?.mobile?.replace(/\D+/g, "").replace(/^(\d{3})(\d{3})(\d{4}).*/, "($1) $2-$3") },
                  {
                    label: "Address",
                    value: profile?.address1 ? profile?.address1 + ", " + profile?.state + " " + profile?.zipcode : " - ",
                  },
                ].map((item) => (
                  <div class="row">
                    <div class="bio-row" style={{ pointer: "cursor" }}>
                      <div class="form-group">
                        <label class="col-md-3 control-label">{item.label}</label>
                        <div class="col-md-8" style={{ color: "black", fontWeight: "bold", fontSize: "1.6em" }}>
                          {item.value}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Formik
                  initialValues={{
                    id: profile?.id,
                    name: profile?.name,
                    mobile: profile?.mobile,
                    authemail: profile?.authemail,
                    address1: profile?.address1,
                    address2: profile?.address2,
                    state: profile?.state,
                    zipcode: profile?.zipcode,
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().max(40, "Must be 40 characters or less").required("Required"),
                    mobile: Yup.number().integer("Must be Number").positive().min(10, "Must be 10 digits").nullable().required("Required"),
                    authemail: Yup.string().email("Invalid Email Address").required("Required"),
                    address1: Yup.string().required("Required"),
                    state: Yup.string().required("Required"),
                    zipcode: Yup.string().required("Required"),
                  })}
                  onSubmit={handleProfile}>
                  {({ values, setFieldValue, errors, touched, ...rest }) => {
                    return (
                      <Form>
                        <div class="content" style={{ padding: 0 }}>
                          {/* <p class="section-title B TT">Create your account with us </p> */}
                          <div class="reg-form" style={{ maxWidth: "85%", marginTop: "1em" }}>
                            <div class="form-field" style={{ margin: 0 }}>
                              <div class="field">
                                <label for="fullname">Name *</label>
                                <Field name="name" component={FormikTextfield} type="text" placeholder="Full Name" />
                              </div>
                            </div>
                            <div class="form-field" style={{ margin: 5 }}>
                              <div class="field">
                                <label for="mobilenumber">Mobile Number*</label>
                                <Field
                                  name="mobile"
                                  component={FormikTextfield}
                                  type="number"
                                  placeholder="_ _ _-_ _ _-_ _ _ _"
                                  onChange={(e) => {
                                    let valid = /^\d{0,10}$/.test(e.target.value)
                                    valid && setFieldValue("mobile", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div class="form-field" style={{ margin: 5 }}>
                              <div class="field">
                                <label for="emailaddress">Email *</label>
                                <Field name="authemail" component={FormikTextfield} type="text" placeholder="example@email.com" />
                              </div>
                            </div>

                            <div class="form-field address" style={{ margin: 5 }}>
                              <div class="field">
                                <label for="address">Address *</label>
                                <Field name="address1" component={FormikTextfield} type="text" placeholder="Street Address" />
                              </div>
                            </div>

                            <div class="form-field unit-state" style={{ margin: 0 }}>
                              <div class="field half" style={{ width: "40%" }}>
                                <div class="form-field suite">
                                  <label for="suite">Unit / Suite / Apartment #</label>
                                  <Field name="address2" component={FormikTextfield} type="text" />
                                </div>
                              </div>
                              <div class="field half state" style={{ width: "30%" }}>
                                <div class="form-select" style={{ margin: "0 1em" }}>
                                  <label for="state">State</label>
                                  <select
                                    name="state"
                                    value={values.state}
                                    onChange={(e) => {
                                      setFieldValue("state", e.target.value)
                                    }}
                                    style={{
                                      width: "80%",
                                      ...(touched["state"] &&
                                        errors["state"] && {
                                          border: "solid red 1px",
                                        }),
                                    }}>
                                    <option>Alabama</option>
                                    <option>Alaska</option>
                                    <option>Arizona</option>
                                    <option>Arkansas</option>
                                    <option>California</option>
                                    <option>Colorado</option>
                                    <option>Connecticut</option>
                                    <option>Delaware</option>
                                    <option>Florida</option>
                                    <option>Georgia</option>
                                    <option>Hawaii</option>
                                    <option>Idaho</option>
                                    <option>Illinois</option>
                                    <option>Indiana</option>
                                    <option>Iowa</option>
                                    <option>Kansas</option>
                                    <option>Kentucky</option>
                                    <option>Louisiana</option>
                                    <option>Maine</option>
                                    <option>Maryland</option>
                                    <option>Massachusetts</option>
                                    <option>Michigan</option>
                                    <option>Minnesota</option>
                                    <option>Mississippi</option>
                                    <option>Missouri</option>
                                    <option>Montana</option>
                                    <option>Nebraska</option>
                                    <option>Nevada</option>
                                    <option>New Hampshire</option>
                                    <option>New Jersey</option>
                                    <option>New Mexico</option>
                                    <option>New York</option>
                                    <option>North Carolina</option>
                                    <option>North Dakota</option>
                                    <option>Ohio</option>
                                    <option>Oklahoma</option>
                                    <option>Oregon</option>
                                    <option>Pennsylvania</option>
                                    <option>Rhode Island</option>
                                    <option>South Carolina</option>
                                    <option>South Dakota</option>
                                    <option>Tennessee</option>
                                    <option>Texas</option>
                                    <option>Utah</option>
                                    <option>Vermont</option>
                                    <option>Virginia</option>
                                    <option>Washington</option>
                                    <option>West Virginia</option>
                                    <option>Wisconsin</option>
                                    <option>Wyoming</option>
                                  </select>
                                </div>
                                {touched["state"] && errors["state"] && (
                                  <div
                                    style={{
                                      fontSize: ".7em",
                                      color: "red",
                                      padding: ".3em 0 0 1em",
                                    }}>
                                    {errors["state"]}
                                  </div>
                                )}
                              </div>
                              <div class="field half zip" style={{ marginLeft: "1em", width: "25%" }}>
                                <div class="form-field">
                                  <label for="zipcode">Zip Code</label>
                                  <Field
                                    name="zipcode"
                                    component={FormikTextfield}
                                    type="number"
                                    style={{ marginLeft: "1em", width: "92%" }}
                                    onChange={(e) => {
                                      let valid = /^\d{0,5}$/.test(e.target.value)
                                      valid && setFieldValue("zipcode", e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            // justifyContent: "center",
                            marginTop: "2em",
                          }}>
                          <button
                            type="submit"
                            style={{
                              fontSize: "1.5em",
                              backgroundColor: "#315698",
                              color: "white",
                              borderRadius: "1em",
                              padding: ".2em 1.5em",
                              border: "none",
                            }}
                            // onClick={handleModalClose}
                          >
                            Update Profile
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </>
            )}
            {/* <div class="row">
                            <div class="bio-row">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">Password:</label>
                                    <div class="col-md-8">
                                        <input class="form-control" value="" type="password" />
                                    </div>
                                </div>
                            </div>
                            <div class="bio-row">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">Confirm password:</label>
                                    <div class="col-md-8">
                                        <input class="form-control" value="" type="password" />
                                    </div>
                                </div>
                            </div>
                            <div class="bio-row">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">Address</label>
                                    <div class="col-md-8">
                                        <textarea class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="bio-row">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">Phone Number:</label>
                                    <div class="col-md-8">
                                        <input class="form-control" value="11111122333" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
