import React, { useState, useEffect, } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import api, { toFormData } from '../../../libs/api'
import { handleApiError } from '../../../utils/handleApiError'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import moment from 'moment';

const ConfirmBooking1 = (props) => {
    const {authUser, booking, category, handleBookingStep } = props
    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }
    return <>
        {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
        <div class='section' id='section7'>
            <div class='content confirm'>
                <p class='section-title B TT'>
                    <a onClick={() => handleBookingStep(4)}>
                        <span class='fa fa-arrow-left'></span>
                    </a>{' '}
                    Confirm Your Booking{' '}
                </p>
                <div class='details'>
                    <p class='issue-title TT B'>
                        {category.level1id ? category.level1id : ' - '} /{' '}
                        {category.level2id ? category.level2id : ' - '}{' '}
                    </p>
                    {/* <p class='issue-title TT B'>Plumbing/Replacement</p>*/}
                    <p class='issue-title sub'>Schedule Your Service</p>
                    <ul>
                        <li>
                            <span class='fa fa-calendar-o'></span>
                            {booking.bookingdate
                                ? moment(booking.bookingdate).format('dddd, MMMM Do YYYY')
                                : ' - '}
                            {/* Tuesday, October 3 */}
                        </li>
                        <li>
                            <span class='fa fa-clock-o'></span>
                            {booking.timeslot
                                ? booking.timeslot +
                                ' (' +
                                booking.appointmentHour +
                                ' hour time frame)'
                                : ' - '}
                            {/* 12:00 pm - 4:00 pm
                        (4 hours time frame) */}
                        </li>
                        <li>
                            <span class='fa fa-user-o'></span>
                            {authUser?.name ? authUser?.name : ' - '}
                            {/* Andrew Smith */}
                        </li>
                        <li>
                            <span class='fa fa-mobile'></span>
                            {authUser?.mobile
                                ? authUser?.mobile.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
                                : ' - '}
                        </li>
                        <li>
                            <span class='fa fa-map-marker'></span>
                            {authUser?.address1
                                ? authUser?.address1 +
                                ', ' +
                                authUser?.state +
                                ' ' +
                                authUser?.zipcode
                                : ' - '}
                            {/* 12345, Wilcrest
                        Drive # 11080, Houston, TX 77090 */}
                        </li>
                        <li>
                            <span class='fa fa-envelope-o'></span>
                            {authUser?.authemail
                                ? authUser?.authemail
                                : ' - '}
                            {/* andrewsmith@gmail.com */}
                        </li>
                    </ul>
                    <small>
                        We'll send you a confirmation email for your appointment.
                    </small>
                </div>
                <a class='button continuebtn-active' onClick={() => handleBookingStep(2)}>
                    Continue <span class='fa fa-arrow-circle-o-right'></span>
                </a>
            </div>
        </div>
    </>;
}

export default ConfirmBooking1;