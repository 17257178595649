import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import PlumbingIcon from "../../../assets/images/icon-plumbing-field.png"
import HVACIcon from "../../../assets/images/icon-plumbing-field.png"
import "../../../assets/scss/checkbox.scss"
import { Navigate, useNavigate } from "react-router-dom"

const Issue = (props) => {
  const {
    category,
    setCategory,
    allCategory,
    setAllCategory,
    shopProducts,
    setShopProducts,
    products,
    setProducts,
    setTechnicians,
    setQuestions,
    handleActiveStep,
  } = props
  const [plumbing, setPlumbing] = useState({})
  const [hvac, setHvac] = useState({})
  const navigate = useNavigate()
  const continueActiveBtn = category.productId ? "button continuebtn-active" : "button continuebtn"

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/categories`)

      let pid = data.find((item) => item.name == "Plumbing" && !item.parent).id
      setPlumbing({ id: pid, list: data.filter((item) => item.parent == pid) })

      let hid = data.find((item) => item.name == "HVAC" && !item.parent).id
      setHvac({ id: hid, list: data.filter((item) => item.parent == hid) })

      setAllCategory(data)
      fetchProducts()
      // fetchTechnicians()
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }

  const fetchProducts = async (values) => {
    try {
      const { data } = await api.get(`v1/products`)
      setProducts(data)
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }

  const fetchTechnicians = async (values) => {
    try {
      const { data } = await api.get(`v1/technician`)
      setTechnicians(data)
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }
  const handleCategoryLevel1 = (value) => {
    setCategory({
      level1id: value,
      level2id: "",
      level3id: "",
      productId: "",
      level3options: [],
      productOptions: [],
    })
    setQuestions([])
  }

  const handleCategoryLevel2 = (value) => {
    let iId = ""
    if (category.level1id == "Plumbing") {
      iId = plumbing.list.find((item) => item.name == value).id
    } else {
      iId = hvac.list.find((item) => item.name == value).id
    }
    setCategory({
      ...category,
      level2id: value,
      level3id: "",
      productId: "",
      level3options: allCategory.filter((item) => item.parent == iId),
      productOptions: [],
    })
  }

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleIssueSubmit = async () => {
    try {
      let validationSchema = [{ field: category.productId, errMsg: "Product required*" }]
      validationSchema.forEach((item) => {
        if (!item.field) {
          throw item.errMsg
        }
      })
      handleActiveStep(2)
    } catch (error) {
      openToast({
        color: "error",
        message: error,
      })
    }
  }
  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      <div class="section" id="section0">
        <div class="content">
          <p class="section-title">
            <a href="https://igdplumbing.com/en/faq/">
              <span class="fa fa-arrow-left"></span> Need help ?
            </a>{" "}
            <span class="subtitle">Schedule your appointment online!</span>
          </p>
          <div class="form-field">
            <form>
              <div class="field half check-container" onClick={() => handleCategoryLevel1("Plumbing")}>
                <input
                  type="radio"
                  id="plumbing"
                  // name='typeRad'
                  value="Plumbing"
                  // checked='true'
                  checked={category.level1id === "Plumbing" ? true : false}
                />
                <span class="checkmark"></span>
                <label for="plumbing"> Plumbing</label>
                {/* <div style={{ backgroundImage: `url(${PlumbingIcon})`,backgroundRepeat:'no-repeat' }}></div> */}
                <img src="images/icon-plumbing-field.png" style={{ float: "right" }} />
              </div>
              <div class="field half last check-container" onClick={() => handleCategoryLevel1("HVAC")}>
                <input
                  type="radio"
                  id="hvac"
                  // name='typeRad'
                  value="HVAC"
                  // checked='false'
                  checked={category.level1id === "HVAC" ? true : false}
                // onChange={() => handleCategoryLevel1('HVAC')}
                />
                <label for="hvac"> HVAC</label>
                <span class="checkmark"></span>
                <img src="images/icon-hvac-field.png" style={{ float: "right" }} />
              </div>
            </form>
          </div>

          <div class="form-field servicetype">
            <div
              class={`field ${category.level2id == "Service" && "field-active"}`}
              onClick={(e) => {
                handleCategoryLevel2("Service")
              }}>
              <img src="images/icon-service.png" />
              Service
            </div>
            <div
              class={`field ${category.level2id == "Repair" && "field-active"}`}
              onClick={() => {
                handleCategoryLevel2("Repair")
              }}>
              <img src="images/icon-repair.png" />
              Repair
            </div>
            <div
              class={`field ${category.level2id == "Replacement" && "field-active"}`}
              onClick={() => {
                handleCategoryLevel2("Replacement")
              }}>
              <img src="images/icon-replacement.png" />
              Replacement
            </div>
            <div
              class={`field ${category.level2id == "Estimate" && "field-active"}`}
              onClick={() => {
                handleCategoryLevel2("Estimate")
              }}>
              <img src="images/icon-estimate.png" />
              Estimate
            </div>
          </div>
          <div class="form-select issuetype">
            <select
              name="issuetype"
              value={category.level3id}
              style={{ color: "#000" }}
              onChange={(e) => {
                setCategory({
                  ...category,
                  level3id: e.target.value,
                  productId: "",
                  productOptions: products.filter((item) => item.categorlevel3id == e.target.value),
                })
                setQuestions(
                  allCategory.find((item) => item.id == e.target.value).questions
                    ? allCategory.find((item) => item.id == e.target.value).questions
                    : []
                )
              }}>
              <option value="" disabled="true" selected="true">
                Select your Issue
              </option>
              {/* <option>Repiping</option> */}
              {category.level3options.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-select issuetype">
                <select
                  name="issuetype"
                  value={category.productId}
                  style={{ color: "#000" }}
                  onChange={(e) => {
                    setCategory({
                      ...category,
                      productId: e.target.value,
                      product: category.productOptions.find((item) => item.id == e.target.value),
                    })
                  }}>
                  <option value="" disabled="true" selected="true">
                    Select your Products
                  </option>
                  {/* <option>Repiping</option> */}
                  {category.productOptions
                    .sort((a, b) => a.sortIndex - b.sortIndex)
                    .map((item) => (
                      <option value={item.id}>
                        {item.productnumber} - {item.headertext}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div class="col-md-1" style={{ paddingTop: "1em" }}>
              OR
            </div>
            <div class="col-md-3 pt-3 shop-products" style={{ paddingTop: "1em" }}>
              <div
                // class={`field field-active`}
                style={{
                  background: category.productOptions.length ? "#315698" : "#cccccc",
                  color: "#ffffff",
                  padding: ".3em .5em .3em .5em",
                  borderRadius: ".5em",
                  cursor: "pointer",
                }}
                onClick={
                  category.productOptions.length
                    ? () => {
                      setShopProducts({
                        open: true,
                        category: `${category.level1id} - ${category.level2id}`,
                        products: category.productOptions,
                        handleBookProduct: (c) =>
                          setCategory({
                            ...category,
                            productId: c.id,
                            product: category.productOptions.find((item) => item.id == c.id),
                          }),
                      })
                    }
                    : null
                }>
                Shop Products
              </div>
            </div>
          </div>
          <div>
            <ul style={{ listStyleType: "disc", fontSize: "1.5rem", margin: "2em 0 0 3em" }}>
              <li>Service – all services that do not require parts</li>
              <li>Repair – all services that require parts or require a repair on an existing fixture</li>
              <li>Replacements – all new installations </li>
              <li>Estimate – all Quotes </li>
            </ul>
          </div>
          {/* <div class='form-select issuetype'>
                    <select
                      name='issuetype'
                      value={category.level4id}
                      onChange={e => {
                        setCategory({
                          ...category,
                          level4id: e.target.value
                        })
                        setQuestions(
                          allCategory.find(item => item.id == e.target.value)
                            .questions
                            ? allCategory.find(
                                item => item.id == e.target.value
                              ).questions
                            : []
                        )
                      }}
                    >
                      <option value='' disabled='true' selected='true'>
                        Select your issue
                      </option>
                      {category.level4options.map(item => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div> */}

          <div class="section-footer">
            <div   >
              <div class={continueActiveBtn} onClick={handleIssueSubmit}>
                Continue <span class="fa fa-arrow-circle-o-right"></span>
              </div>
              <div style={{
                borderRadius: "0",
                color: "#000",
                cursor: "pointer",
                fontSize: "14px",
                left: "30%",
                // padding: "1.25% ",
                position: " absolute",
                bottom: " 5%",
                width: "67%",
                textAlign: "justify"
              }} >
                At IGD Plumbing and Air, we're always looking to improve and expand our services to meet your needs. If there's an additional service, you'd like us to offer, please let us know by filling out this form. Your feedback is invaluable to us!
                <br />  <a href="https://forms.office.com/r/BMXa7TeMhi?origin=lprLink" target="_blank" >Click here</a>
              </div>
            </div>
            <div class="copyright">
              &copy; Copyright IGD Plumbing & Air 2023. All Rights Reserved. Powered by{" "}
              <a href="https://www.kbizzsolutions.com" target="_blank">
                K Business Solutions Inc.
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Issue