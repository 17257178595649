import React, { useState, useEffect } from 'react'
import api, { toFormData } from '../../libs/api'
import { handleApiError } from '../../utils/handleApiError'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'

const Membership = props => {
    const [memberships, setMemberships] = useState([])
    let authUser = JSON.parse(localStorage.getItem('_authUser'))

    const fetchMemberships = async values => {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${authUser.authToken}`;
            const { data } = await api.get(`v1/subscription`)
            setMemberships(data)
            // openToast({ color: 'success', message: 'Successfull' })
        } catch (error) {
            handleApiError(error, openToast)
        }
    }
    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }
    useEffect(() => {
        fetchMemberships()
    }, [])
    return (
        <>
            <div class="membership-details  col-md-9">
                <div class="panel">
                    <div class="panel-body bio-graph-info">
                        <h1>Membership Details</h1>
                        <div class=" row">
                            <div class="table-responsive">
                                <table class="table table-hover" style={{ fontSize: '1.4em' }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Start Date</th>
                                            <th>Plan</th>
                                            <th>End Date</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody id="items">
                                        {/* Membership */}
                                        {memberships.map((item, index) => <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{moment(item.startDate.slice(0, 10), 'DD-MM-YYYY').format('MM-DD-YYYY')}</td>
                                            <td>{item.type}</td>
                                            <td>{moment(item.endDate.slice(0, 10), 'DD-MM-YYYY').format('MM-DD-YYYY')}</td>
                                            <td>{item.amount}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Membership
