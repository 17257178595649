import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"

const MoreDetails = (props) => {
  const { handleMoreDetailsText, handleIssueDetailsStep } = props
  // const continueActiveBtn = uploadImgs ? 'button continuebtn-active' : 'button continuebtn'

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ fontSize: "15px" }} />
      <div class="section" id="section3">
        <div class="content">
          <p class="section-title">
            <a onClick={() => handleIssueDetailsStep(2)}>
              <span class="fa fa-arrow-left"></span>
            </a>
          </p>
          <div class="form-field">
            <p class="issue-title">Are there any additional details you would like to share about your issue?</p>
          </div>

          <div class="form-field textbox">
            <textarea
              name="moredetails"
              id="moredetails"
              rows="6"
              cols="50"
              onChange={handleMoreDetailsText}
              placeholder="Write your message here"></textarea>
          </div>

          <a class="button continuebtn-active" onClick={() => handleIssueDetailsStep(4)}>
            Continue <span class="fa fa-arrow-circle-o-right"></span>
          </a>
        </div>
      </div>
    </>
  )
}

export default MoreDetails
