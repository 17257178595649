import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"

const ChooseLogin = (props) => {
  const { booking, setBooking, handleUserDetailsStep } = props
  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      <div class="section" id="section4">
        <div class="content">
          <p class="section-title">
            <a onClick={() => handleUserDetailsStep(0)}>
              <span class="fa fa-arrow-left"></span>
            </a>{" "}
            Have we served you in the past ?
          </p>
          <div class="form-field">
            <div class={booking.pastservice ? "pill selected" : "pill"} onClick={() => setBooking({ ...booking, pastservice: true, account: false })}>
              Yes
            </div>
            <div
              class={booking.pastservice ? "pill" : "pill selected"}
              onClick={() => setBooking({ ...booking, pastservice: false, account: true })}>
              No
            </div>
          </div>
          <>
            <div class="form-field">
              {/* <p class="issue-title">Would you like to create account with us continue as guest ?</p> */}
              {!booking.pastservice ? (
                <>
                  <p class="issue-title">Please Create an Account</p>
                  <div
                    class={booking.account ? "pill selected" : "pill"}
                    // onClick={() => setBooking({ ...booking, account: true })}
                    onClick={() => handleUserDetailsStep(booking.customer.name ? 4 : booking.account ? 2 : 3)}
                  >
                    Create Account
                  </div>
                </>
              ) : (
                <>
                  <p class="issue-title">Login to Continue</p>
                  <div
                    class={!booking.account ? "pill selected" : "pill"}
                    // onClick={() => setBooking({ ...booking, account: false })}
                    onClick={() => handleUserDetailsStep(booking.customer.name ? 4 : booking.account ? 2 : 3)}
                  >
                    Login
                  </div>
                </>
              )}
            </div>
            <br />
            <br />
            <p>Let's get your customer profile set up so we can book your service.</p>
          </>
          <a class="button continuebtn-active" onClick={() => handleUserDetailsStep(booking.customer.name ? 4 : booking.account ? 2 : 3)}>
            Continue <span class="fa fa-arrow-circle-o-right"></span>
          </a>
        </div>
      </div>
    </>
  )
}

export default ChooseLogin
