import React, { useState, useEffect, } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import api, { toFormData } from '../../../libs/api'
import { handleApiError } from '../../../utils/handleApiError'
import MoreDetails from './MoreDetails';
import Questions from './Questions';
import Upload from './Upload';

const IssueDetails = (props) => {
    const { questions, setQuestions, uploadImgs, setUploadImgs, handleActiveStep, handleMoreDetailsText } = props
    const [issueDetailsStep, setIssueDetailsStep] = useState(1)

    const handleIssueDetailsStep = (s) => {
        setIssueDetailsStep(s)
    }

    const handleUploadFile = async (file, index) => {
        try {
            const { data } = await api.post(
                `v1/booking/file`,
                toFormData({ file: file })
            )
            setUploadImgs(
                uploadImgs.map((uItem, uInd) => {
                    if (uInd == index) {
                        return data
                    } else {
                        return uItem
                    }
                })
            )
        } catch (error) {
            // handleApiError(error, setToast)
            handleApiError(error, openToast)
        }
    }

    const handleDeleteFile = (index) => {
        setUploadImgs(uploadImgs.map((uItem, uInd) => {
            if (uInd == index) {
                return { valueUrl: '' }
            }
            else {
                return uItem
            }
        }))
    }
    const issueDetailsComp = [
        { id: 1, component: <Questions questions={questions} setQuestions={setQuestions} handleIssueDetailsStep={handleIssueDetailsStep} /> },
        { id: 2, component: <Upload uploadImgs={uploadImgs} handleUploadFile={handleUploadFile} handleDeleteFile={handleDeleteFile} handleIssueDetailsStep={handleIssueDetailsStep} handleBackStep={() => { !questions.length ? handleActiveStep(1) : handleIssueDetailsStep(1) }} /> },
        { id: 3, component: <MoreDetails handleMoreDetailsText={handleMoreDetailsText} handleIssueDetailsStep={handleIssueDetailsStep} /> },
    ]

    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }

    useEffect(() => {
        if (issueDetailsStep == 0) {
            handleActiveStep(1)
            setIssueDetailsStep(1)
        } else if (issueDetailsStep == 4) {
            handleActiveStep(3)
            setIssueDetailsStep(1)
        }
    }, [issueDetailsStep])

    useEffect(() => {
        if (!questions.length) {
            setIssueDetailsStep(2)
        }
    }, [])

    return <>
        {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
        {issueDetailsComp.find(item => item.id == issueDetailsStep)?.component ?? <></>}

    </>;
}

export default IssueDetails;