import React, { useState, useEffect } from 'react'
import api, { toFormData } from '../../libs/api'
import { handleApiError } from '../../utils/handleApiError'
import { shiftTzDateToPickerDate } from '../../utils/dateUtils'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { computeHeadingLevel } from '@testing-library/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { upload } from '@testing-library/user-event/dist/upload'

import logoImg from '../../assets/images/igd-logo.png'

import gImg from '../../assets/images/1.PNG'
import l1Img from '../../assets/images/l1.PNG'
import l2Img from '../../assets/images/l2.PNG'
import l3Img from '../../assets/images/l3.PNG'
import '../../assets/styles/shop-product.css'
import '../../assets/scss/my-account.scss'
import Profile from './Profile'
import Booking from './Booking'
import Membership from './Membership'

const MyAccount = props => {
    const { handleOverlay, booking } = props
    const [activeNav, setActiveNav] = useState('profile')
    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }
    const navList = [
        { id: 'booking', label: 'Booking Details', icon: <i class="fa fa-calendar"></i>, component: <Booking /> },
        { id: 'membership', label: 'Membership Details', icon: <i class="fa fa-edit"></i>, component: <Membership /> },
        { id: 'profile', label: 'Profile', icon: <i class="fa fa-user"></i>, component: <Profile booking={booking} /> },
    ]
    
    useEffect(() => {
        if (window.location.search.includes('payment=success'))
            setActiveNav('booking')
    }, [])

    return (
        <div class="main">
            <section class="container" >
                <div class="container">
                    <a href='#' class='fa fa-times close-btn' onClick={() => {
                        handleOverlay('')
                        window.location.href = "/"
                    }
                    }></a>
                    <div class="row">
                        <div class="profile-nav col-md-3">
                            <div class="panel">
                                <div class="user-heading round">
                                    <a href="#">
                                        <img src={logoImg} alt="" />
                                    </a>
                                    <h1>{booking.customer.name}</h1>
                                    <p>{booking.customer.authemail}</p>
                                </div>

                                <ul class="nav nav-pills nav-stacked">
                                    {navList.map(item =>
                                        <li key={item.id} class={activeNav == item.id ? 'active' : ''}>
                                            <a onClick={() => setActiveNav(item.id)}>{item.icon}<span style={{ fontSize: '1.5em' }}>{item.label}</span> </a>
                                        </li>)
                                    }
                                </ul>
                            </div>
                        </div>
                        {navList.find(item => item.id == activeNav)?.component ?? <></>}
                        {/* <div class="profile-info col-md-9">
                            <div class="panel">
                                <div class="panel-body bio-graph-info">
                                    <h1>Profile</h1>
                                    <div class="row">
                                        <div class="bio-row">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label">Password:</label>
                                                <div class="col-md-8">
                                                    <input class="form-control" value="" type="password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bio-row">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label">Confirm password:</label>
                                                <div class="col-md-8">
                                                    <input class="form-control" value="" type="password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bio-row">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label">Address</label>
                                                <div class="col-md-8">
                                                    <textarea class="form-control" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bio-row">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label">Phone Number:</label>
                                                <div class="col-md-8">
                                                    <input class="form-control" value="11111122333" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="booking-details  col-md-9">
                            <div class="panel">
                                <div class="panel-body bio-graph-info">
                                    <h1>Booking Details</h1>
                                    <div class=" row">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Timeslot</th>
                                                        <th>Technician</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="items">
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="membership-details  col-md-9">
                            <div class="panel">
                                <div class="panel-body bio-graph-info">
                                    <h1>Membership Details</h1>
                                    <div class=" row">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Start Date</th>
                                                        <th>Plan</th>
                                                        <th>End Date</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="items">
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                        <td>Table cell</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </div>
                </div>
            </section>

            <footer style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <p>Copyrights IGD Plumbing & Air 3. All Rights Reserved. Powered By K Business Solutions Inc.</p>
            </footer>
            {/* <script src="https://code.jquery.com/jquery-1.12.4.min.js" integrity="sha384-nvAa0+6Qg9clwYCGGPpDQLVpLNn0fRaROjHqs13t4Ggj3Ez50XnGQqc/r8MhnRDZ" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/js/bootstrap.min.js" integrity="sha384-aJ21OjlMXNL5UyIl/XNwTMqvzeRMZH2w8c5cRVpzpU8Y5bApTppSuUkhZXN0VxHd" crossorigin="anonymous"></script> */}

        </div>
    )
}

export default MyAccount
