import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../libs/api"
import { handleApiError } from "../../utils/handleApiError"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import useLocalStorage from "../../utils/useLocalStorage"
import logoImg from "../../assets/images/igd-logo.png"

export const ForgotPasswordModal = (props) => {
  const { handleForgotPassword, handleModalClose, handleOpenRegister } = props

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }
  return (
    <>
      <div class="modal-window">
        <div class="modal-content" style={{ width: "70vh" }}>
          <a href="#" class="fa fa-times close-btn modal-close" style={{ fontSize: "2em" }} onClick={handleModalClose}></a>
          <div>
            <p class="modal-title" style={{ fontSize: "3em", textAlign: "center", fontWeight: "bold" }}>
              Forgot Password
            </p>

            <Formik
              initialValues={{
                authemail: "",
              }}
              validationSchema={Yup.object({
                authemail: Yup.string().email("Invalid email address").required("Required"),
              })}
              onSubmit={handleForgotPassword}>
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <div class="reg-form" style={{ maxWidth: "100%" }}>
                      <div class="form-field">
                        <div class="field">
                          {/* <label for='emailaddress'>Email *</label> */}
                          <p class="modal-title" style={{ fontSize: "2em", textAlign: "center", marginTop: "1em" }}>
                            Reset link will be sent to this email{" "}
                          </p>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <Field
                              name="authemail"
                              type="text"
                              placeholder="Email"
                              style={{ fontSize: "2em", borderRadius: "1.5em", paddingLeft: "1em", width: "90%" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-field"></div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "6em" }}>
                      <button
                        type="submit"
                        style={{
                          fontSize: "2em",
                          backgroundColor: "#315698",
                          color: "white",
                          borderRadius: "1em",
                          padding: ".2em 1.5em",
                          border: "none",
                        }}
                        // onClick={handleModalClose}
                      >
                        Send Email
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div></div>
        </div>
      </div>
    </>
  )
}

export const ResetPassword = (props) => {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get("token")
  const [showPass1, setShowPass1] = useState(false)
  const [showPass2, setShowPass2] = useState(false)

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }
  const handleResetPassword = async (values) => {
    try {
      const { data } = await api.post(`v1/forgot-password/reset-password`, values)
      openToast({ color: "success", message: "Reset Password Successfully" })
      navigate("/")
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  return (
    <>
      <div class="main">
        <section class="container">
          <div class="container">
            <div class="row" style={{ display: "flex", justifyContent: "center" }}>
              <div class="profile-nav col-md-6 ">
                <div class="panel" style={{ margin: "0" }}>
                  <div class="user-heading round">
                    <a>
                      <img src={logoImg} alt="" />
                    </a>
                    {/* <h1>{booking.customer.name}</h1>
                                    <p>{booking.customer.authemail}</p> */}
                    <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>Reset Password</h1>
                  </div>
                  <Formik
                    initialValues={{
                      password: "",
                      repeatPassword: "",
                      token: token,
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string()
                        .matches(
                          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        )
                        .required("Required*"),
                      repeatPassword: Yup.string()
                        .oneOf([Yup.ref("password"), null], "Passwords must match")
                        .required("Required*"),
                    })}
                    onSubmit={handleResetPassword}>
                    {({ values, setFieldValue, errors }) => {
                      return (
                        <Form>
                          <div class="reg-form" style={{ maxWidth: "100%" }}>
                            <div class="form-field">
                              <div class="field position-relative">
                                <label for="password" style={{ fontSize: "2em" }}>
                                  New Password *
                                </label>
                                <Field
                                  name="password"
                                  type={showPass1 ? "text" : "password"}
                                  placeholder="Enter Password"
                                  style={{ fontSize: "2em", borderRadius: "1.5em", paddingLeft: "1em", width: "100%" }}
                                />
                                <div className="passEyeIcon" style={{ top: 35, right: 20 }}>
                                  <i
                                    className={`fa ${showPass1 ? "fa-eye" : "fa-eye-slash"} h4`}
                                    role="button"
                                    onClick={() => setShowPass1(!showPass1)}></i>
                                </div>
                                {errors["password"] && (
                                  <small style={{ paddingLeft: "1em", color: "red", fontSize: "1.5em" }}>{errors["password"]}</small>
                                )}
                              </div>
                            </div>
                            <div class="form-field">
                              <div class="field position-relative">
                                <label for="password" style={{ fontSize: "2em" }}>
                                  Confirm Password *
                                </label>
                                <Field
                                  name="repeatPassword"
                                  type={showPass2 ? "text" : "password"}
                                  placeholder="Enter Password"
                                  style={{ fontSize: "2em", borderRadius: "1.5em", paddingLeft: "1em", width: "100%" }}
                                />
                                <div className="passEyeIcon" style={{ top: 35, right: 20 }}>
                                  <i
                                    className={`fa ${showPass2 ? "fa-eye" : "fa-eye-slash"} h4`}
                                    role="button"
                                    onClick={() => setShowPass2(!showPass2)}></i>
                                </div>
                                {errors["repeatPassword"] && (
                                  <small style={{ paddingLeft: "1em", color: "red", fontSize: "1.5em" }}>{errors["repeatPassword"]}</small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="form-field">
                            <small style={{ paddingLeft: "1em", color: "gray", fontSize: "1.5em" }}>Reset Passsword within 5 min*</small>
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", marginTop: "7em" }}>
                            <button
                              type="submit"
                              style={{
                                fontSize: "2em",
                                backgroundColor: "#315698",
                                color: "white",
                                borderRadius: "1em",
                                padding: ".2em 1.5em",
                                border: "none",
                              }}
                              // onClick={handleModalClose}
                            >
                              Reset Password
                            </button>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <p>Copyrights IGD Plumbing & Air 3. All Rights Reserved. Powered By K Business Solutions Inc.</p>
        </footer>
      </div>
    </>
  )
}
