import * as React from "react"
import { Routes, Route, Link } from "react-router-dom"
import Home from "../pages/Home"
import ShopProducts from "../pages/ShopProducts"
import StepperLayout from "../pages/StepperLayout"
import { ResetPassword } from "../pages/my-account/ForgotPassword"
// import Login from "../pages/auth/Login";
// import Dashboard from "../pages/Dashboard";
// import Topbar from "../layouts/Topbar";
// import Sidebar from "../layouts/Sidebar";
// import AuthLayout from "../layouts/AuthLayout.";
// import "../assets/styles/Layout1.css";

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="" element={<StepperLayout />} />
        <Route path="/forgotpassword" element={<ResetPassword />} />
        {/* <Route path="/" element={<Home />} /> 
         <Route path="/shop-products" element=  {<ShopProducts />} /> 
         <Route path="/dashboard" element={<Dashboard />} /> 
         <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<Sidebar />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route> */}
      </Routes>
    </>
  )
}
