import React from "react"

export const FormikTextfield = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onChange,
  ...props
}) => {
  let err = touched[field.name] && errors[field.name]
  return (
    <div>
      <input type="text" {...field} {...props} style={err && { border: "solid red 1px" }} {...(onChange && { ...{ onChange: onChange } })} />
      {err && <div style={{ fontSize: ".7em", color: "red", padding: ".3em 0 0 1em" }}>{errors[field.name]?.message}</div>}
    </div>
  )
}
