import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import moment from "moment"
import DatePicker from "react-date-picker"
// import "react-datepicker/dist/react-datepicker.css"
// import "../../../assets/styles/react-datepicker-custom.css"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import "../../../assets/styles/react-datepicker-custom.css"

const ScheduleTime = (props) => {
  const { authUser, category, appointment, setAppointment, booking, setBooking, technicians, setTechnicians, timeslots, handleActiveStep } = props
  const [modalAlert, setModalAlert] = useState(false)
  var disableDate = new Date()
  disableDate.setDate(disableDate.getDate() + 1)

  const fetchTechnicians = async (values) => {
    try {
      const { data } = await api.get(`v1/technician/customer/${authUser?.zipcode}/${category.level1id}`)
      setTechnicians(data)
      if (!data.length) setModalAlert(true)
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  const validateStep = (isBtn) => {
    try {
      let err = false
      let validationSchema = [
        { field: appointment.vHour, errMsg: "Appointment Hour required*" },
        { field: appointment.vDate, errMsg: "Appointment required*" },
        { field: booking.technicianId, errMsg: "Technician required*" },
        { field: booking.timeslotId, errMsg: "Timeslot required*" },
      ]
      validationSchema.forEach((item) => {
        if (isBtn && !item.field) {
          err = true
          return
        }
        if (!item.field) {
          throw item.errMsg
        }
      })
      return err
    } catch (error) {
      openToast({ color: "error", message: error })
    }
  }

  useEffect(() => {
    fetchTechnicians()
  }, [])

  return (
    <>
      <div class="section" id="section6">
        <div class="content">
          <p class="section-title B TT">
            <a
              onClick={() => {
                if (authUser?.name) {
                  handleActiveStep(2)
                } else {
                  handleActiveStep(3)
                }
              }}>
              <span class="fa fa-arrow-left"></span>
            </a>{" "}
            Schedule an arrival time{" "}
          </p>
          <div class="form-field appointmentwindow">
            <div class="field  hour">
              <div class="form-select">
                <label for="state">Select your appointment window</label>
                <select
                  name="state"
                  value={appointment.vHour}
                  onChange={(e) => {
                    setAppointment({ ...appointment, vHour: e.target.value })
                    setBooking({
                      ...booking,
                      timeslot: "",
                      timeslotId: "",
                      appointmentHour: "",
                    })
                  }}>
                  <option>Select your appointment window</option>
                  <option value="1">1 hour</option>
                  <option value="2">2 hours</option>
                  <option value="3">3 hours</option>
                  <option value="4">4 hours</option>
                </select>
              </div>
            </div>
            <div class="field half hour">
              <div class="form-field">
                <label for="date">Select your Date</label>
                <div>
                  {/* <input
                    type="date"
                    value={appointment.vDate}
                    min={moment(new Date()).add(1, "days").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      // fetchTimeslot(e.target.value)
                      setAppointment({ ...appointment, vDate: moment(e.target.value).format("MM-DD-YYYY") })
                      setBooking({ ...booking, timeslot: "", timeslotId: "", appointmentHour: "" })
                    }}
                  /> */}
                  <DatePicker
                    format={"MM-dd-yyyy"}
                    placeholderText="MM-DD-YYYY"
                    minDate={disableDate}
                    // minDate={new Date()}
                    // minDate={moment(new Date()).add(1, "days").format("YYYY-MM-DD")}
                    value={new Date(appointment.vDate)}
                    clearIcon={<></>}
                    onChange={(date) => {
                      if (date) {
                        setAppointment({ ...appointment, vDate: moment(date).format("MM-DD-YYYY") })
                        setBooking({ ...booking, timeslot: "", timeslotId: "", appointmentHour: "" })
                      }
                    }}
                  />
                </div>
                {/* <div id='calendar-inline'></div> */}
              </div>
            </div>
            <div class="field half hour">
              <div class="form-field">
                <label for="date" style={{ display: "block" }}>
                  Select Technician
                </label>
                <select
                  name="technician"
                  value={booking.technicianId}
                  style={{ width: "80%", marginTop: "2px", height: "2em" }}
                  onChange={(e) => {
                    setBooking({
                      ...booking,
                      technician: technicians.find((item) => item.id == e.target.value),
                      technicianId: e.target.value,
                    })
                    setAppointment({ ...appointment, technicianId: e.target.value })
                  }}>
                  <option disabled="true" selected="true">
                    Select Technician
                  </option>
                  {technicians.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div class="clearfix clear"></div>
          </div>

          <div class="form-field">
            <p class="issue-title">{booking.bookingdate && moment(booking.bookingdate).format("dddd, MMMM Do YYYY")}</p>
            {timeslots.map((tItem) => (
              <div
                class={tItem.id == booking.timeslotId ? "pill selected" : "pill"}
                onClick={() => {
                  setBooking({
                    ...booking,
                    timeslot: tItem.description,
                    timeslotId: tItem.id,
                    appointmentHour: appointment.vHour,
                  })
                }}>
                {tItem.description}
              </div>
            ))}
          </div>
          <br />
          <small>
            If you'd like to schedule an appointment for a time not listed in our online calendar, <br /> please call us at 832 - 701 - 8666.
          </small>
          <a
            class={!validateStep("btn") ? "button continuebtn-active" : "button continuebtn"}
            onClick={() => {
              if (validateStep() == false) {
                handleActiveStep(5)
              }
            }}>
            Continue <span class="fa fa-arrow-circle-o-right"></span>
          </a>
        </div>
      </div>
      {modalAlert && (
        <div class="modal-window">
          <div class="modal-content" style={{ fontSize: "2rem" }}>
            <a href="#" class="fa fa-times close-btn modal-close" onClick={() => setModalAlert(false)}></a>
            <p class="modal-title" style={{ fontSize: "2rem", margin: "1em 0" }}>
              Sorry! No technician available at this Zipcode.
              {/* <i class="fa fa-info"></i> */}
              <p style={{ textAlign: "center", marginTop: "1em" }}>
                Please call us at <b> 832-701-8666</b>
              </p>
            </p>
            {/* <div>
              <div class="form-field">
                <p class="modal-title" style={{ fontWeight: "bold", fontSize: "1em" }}>
                  {moment(price.nextWednesday).format("dddd, MMMM Do YYYY")}
                </p>
              </div>
            </div> */}
            {/*<div class="modal-actions">
               <div>
                      <button
                        onClick={async () => {
                          setPriceOffer({ offer: "wed", data: price.nextWednesday })
                          let p = await fetchBookingPriceDifference({ offer: "wed", data: price.nextWednesday })
                          setPriceDiff(p)
                          handleModalClose()
                        }}>
                        Save
                      </button>
                    </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  )
}

export default ScheduleTime
