import React, { useState, useEffect, } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import api, { toFormData } from '../../../libs/api'
import { handleApiError } from '../../../utils/handleApiError'
import trashIcon from '../../../assets/images/trash-blue.svg'

const Upload = (props) => {
    const { uploadImgs, handleUploadFile, handleDeleteFile, handleIssueDetailsStep, handleBackStep } = props
    const continueActiveBtn = uploadImgs ? 'button continuebtn-active' : 'button continuebtn'

    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }
    useEffect(() => {
    }, [])

    return <>
        <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        />
        <div class='section' id='section2'>
            <div class='content'>
                <p class='section-title'>
                    <a onClick={handleBackStep}>
                        <span class='fa fa-arrow-left'></span>
                    </a>
                </p>
                <div class='form-field'>
                    <p class='issue-title'>
                        <b>Uploading a video or photograph of the issue</b> is the
                        best way to help us assign the best technician and send
                        the equipment.
                    </p>
                    <small>
                        Drag and drop your videos and photos or click to upload
                    </small>
                </div>
                <div class='form-field fileupload'>
                    {[0, 1, 2, 3].map(ind => (
                        <>
                            {uploadImgs[ind].valueUrl ? (
                                <div class='field img-field' style={{ position: 'relative' }}>
                                    <div class="trash-icon"
                                        onClick={() => handleDeleteFile(ind)}
                                    >
                                        <img src={trashIcon} />
                                    </div>
                                    <img
                                        src={uploadImgs[ind].valueUrl}
                                        style={{
                                            outline: 'none',
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            margin: '1em',
                                            zIndex: '100',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div class='field' style={{ position: 'relative' }}>
                                    <span class='fa fa-plus'></span>
                                    <input
                                        accept='image/*,video/*'
                                        style={{
                                            // display: 'none',
                                            outline: 'none',
                                            opacity: '0',
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        // class='fa fa-plus'
                                        id='outlined-button-file1'
                                        // multiple
                                        type='file'
                                        onChange={async e => {
                                            e.preventDefault()
                                            let file = e.target.files[0]
                                            let fileType = file.type
                                            let reader = new FileReader()
                                            reader.onloadend = () => {
                                                handleUploadFile(file, ind)
                                                // setUploadImgs(
                                                //   uploadImgs.map((uItem, uIndex) => {
                                                //     if (uIndex == ind) {
                                                //       return {
                                                //         file: file,
                                                //         url: reader.result,
                                                //         type: fileType.substring(0, 5)
                                                //       }
                                                //     } else {
                                                //       return uItem
                                                //     }
                                                //   })
                                                // )
                                            }
                                            reader.readAsDataURL(file)
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    ))}
                    {/* <div class='field'>
                      <span class='fa fa-plus'></span>
                    </div>
                    <div class='field'>
                      <span class='fa fa-plus'></span>
                    </div>
                    <div class='field'>
                      <span class='fa fa-plus'></span>
                    </div> */}
                </div>

                <a class='button continuebtn-active' onClick={() => handleIssueDetailsStep(3)}>
                    {/* Skip This */}
                    Continue <span class='fa fa-arrow-circle-o-right'></span>
                </a>
            </div>
        </div>
    </>;
}

export default Upload;