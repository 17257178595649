import React, { useState, useEffect, useRef, createContext } from "react"
import { useLocation } from "react-router-dom"
import api, { toFormData } from "../libs/api"
import { ToastContainer, toast } from "react-toastify"
import { handleApiError } from "../utils/handleApiError"
import Issue from "./steps/issue"
import IssueDetails from "./steps/issue-details"
import UserDetails from "./steps/user-details"
import ScheduleTime from "./steps/schedule-time"
import ConfirmBooking from "./steps/confirm-booking"
import { shiftTzDateToPickerDate } from "../utils/dateUtils"
import moment from "moment"
import "../assets/scss/stepper.scss"
import "../assets/scss/shop-products.scss"
import "../assets/scss/dropdown.scss"
import ShopProducts from "./ShopProducts2"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { FormikTextfield } from "../components/FormikTextfield"
import useLocalStorage from "../utils/useLocalStorage"
import MyAccount from "./my-account/Layout"
import LoginModal from "./my-account/Login"
import RegisterModal from "./my-account/Register"
import { ForgotPasswordModal, ResetPasswordModal } from "./my-account/ForgotPassword"

export const StoreContext = createContext()

const bookingSchema = {
  modedetails: "",
  pastservice: false,
  account: true,
  timeslot: "",
  timeslotId: "",
  bookingdate: "",
  customer: {
    authemail: "",
    authpassword: "",
    name: "",
    mobile: "",
    address1: "",
    address2: "",
    state: "",
    zipcode: "",
  },
  files: [],
  wedOfferApplied: false,
}

const StepperLayout = () => {
  const { getAppItem, setAppItem, clearAll, removeAppItem } = useLocalStorage()
  const [authUser, setAuthUser] = useState(JSON.parse(getAppItem("_authUser", null)))
  const location = useLocation()
  const stepperRef = useRef(null)
  const [activeStep, setActiveStep] = useState(1)
  const [completedStep, setCompletedStep] = useState(1)
  const [category, setCategory] = useState({
    level1id: "Plumbing",
    level2id: "",
    level3id: "",
    level4id: "",
    productId: "",
    level3options: [],
    productOptions: [],
  })
  const [allCategory, setAllCategory] = useState([])
  const [products, setProducts] = useState([])
  const [questions, setQuestions] = useState([])

  const [allTimeslots, setAllTimeslots] = useState([])
  const [timeslots, setTimeslots] = useState([])
  const [technicians, setTechnicians] = useState([])

  const [booking, setBooking] = useState(bookingSchema)
  const [uploadImgs, setUploadImgs] = useState([
    // { file: null, url: null, type: '' },
    { valueUrl: null },
    { valueUrl: null },
    { valueUrl: null },
    { valueUrl: null },
  ])
  const [appointment, setAppointment] = useState({ vDate: moment(new Date()).add(1, "days").format("MM-DD-YYYY"), vHour: "", technicianId: "" })
  const [activeOverlay, setActiveOverlay] = useState(null)
  const [shopProducts, setShopProducts] = useState({ open: false, data: {} })
  const [authModal, setAuthModal] = useState(null)

  const handleActiveStep = (s) => {
    setActiveStep(s)
    setCompletedStep(s)
  }
  const handleStepperClick = (id) => {
    if (id <= completedStep) {
      setActiveStep(id)
    }
  }

  const stepStyle = (ind) => {
    let styleStr = ""
    if (completedStep >= ind) styleStr += "active "
    if (activeStep == ind) styleStr += "selected "
    return styleStr
  }

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  const handleUploadFile = async (file, index) => {
    try {
      const { data } = await api.post(`v1/booking/file`, toFormData({ file: file }))
      setUploadImgs(
        uploadImgs.map((uItem, uInd) => {
          if (uInd == index) {
            return data
          } else {
            return uItem
          }
        })
      )
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  const handleLogin = async (values) => {
    try {
      const { data } = await api.post(`v1/booking/authenticate`, values)
      setBooking({ ...booking, customer: data })
      setAuthModal(null)
      setAppItem("_authUser", JSON.stringify(data))
      setAuthUser(data)
      openToast({ color: "success", message: "Successfully Logged In !!!" })
      if (activeStep == 3) {
        setActiveStep(4)
        setCompletedStep(3)
      }
    } catch (error) {
      handleApiError(error, openToast)
    }
  }
  const handleRegister = async (values) => {
    try {
      const { data } = await api.post(`v1/auth/customer/signup`, values)
      setBooking({ ...booking, customer: data })
      setAppItem("_authUser", JSON.stringify(data))
      setAuthUser(data)
      setAuthModal(null)
      openToast({ color: "success", message: "Successfull" })
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  const handleForgotPassword = async (values) => {
    try {
      const { data } = await api.get(`v1/forgot-password/customer?email=${values.authemail}`)
      setAuthModal(null)
      openToast({ color: "success", message: "Reset Link sent to your Email" })
    } catch (error) {
      handleApiError(error, openToast)
    }
  }
  const fetchTimeslot = async (appnt) => {
    try {
      let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      if (!appnt) {
        let day = moment(appointment.vDate).day()
        const { data } = await api.post(`v1/time/day`, {
          day: weekdays[day],
          bookingDate: moment(appointment.vDate).format("YYYY-MM-DD"),
          timeSlot: appointment.vHour,
          technicianId: appointment.technicianId,
        })
        setBooking({ ...booking, bookingdate: appointment.vDate, calendarType: appointment.vHour })
        setAllTimeslots(data)
        setTimeslots(data.filter((item) => item.calendarType == appointment.vHour))
      } else {
        let day = moment(appnt.vDate).day()
        const { data } = await api.post(`v1/time/day`, {
          day: weekdays[day],
          bookingDate: moment(appnt.vDate).format("YYYY-MM-DD"),
          timeSlot: appnt.vHour,
          technicianId: appnt.technicianId,
        })
        setAppointment(appnt)
        setBooking({ ...booking, bookingdate: appnt.vDate, calendarType: appnt.vHour })
        setAllTimeslots(data)
        setTimeslots(data.filter((item) => item.calendarType == appnt.vHour))
      }
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }

  const fetchBookingPrice = async (pd) => {
    try {
      const { data } = await api.post(`v1/booking/getBookingPrice`, {
        productId: category.productId,
        technicianId: booking.technicianId,
        timeSlotId: pd?.offer == "4hr" ? pd.data.id : booking.timeslotId,
        appointmentDate: pd?.offer == "wed" ? moment(pd.data).format("DD/MM/YYYY") : moment(appointment.vDate).format("DD/MM/YYYY"),
        userId: booking.customer.id,
      })
      setBooking({
        ...booking,
        ...data,
        timeslot: pd?.data?.description ?? booking.timeslot,
        timeslotId: pd?.offer == "4hr" ? pd.data.id : booking.timeslotId,
        appointmentHour: pd?.offer == "4hr" ? "4" : booking.appointmentHour,
        bookingdate: pd?.offer == "wed" ? moment(pd.data).format("YYYY-MM-DD") : booking.bookingdate,
        wedOfferApplied: pd?.offer == "wed" ? true : booking.wedOfferApplied,
      })
      if (pd) {
        setAppointment({
          ...appointment,
          vDate: typeof pd.data == "string" ? moment(pd.data).format("MM-DD-YYYY") : appointment.vDate,
          vHour: pd?.offer == "4hr" ? "4" : appointment.vHour,
        })
      }
      return data
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }
  const fetchBookingPrice2 = async (pd) => {
    try {
      const { data } = await api.post(`v1/booking/getBookingPrice`, {
        productId: category.productId,
        technicianId: booking.technicianId,
        timeSlotId: pd.offer == "4hr" ? pd.data.id : booking.timeslotId,
        appointmentDate: pd.offer == "wed" ? moment(pd.data).format("DD/MM/YYYY") : moment(appointment.vDate).format("DD/MM/YYYY"),
        userId: booking.customer.id,
      })
      setBooking({
        ...booking,
        ...data,
        timeslotId: pd.offer == "4hr" ? pd.data.id : booking.timeslotId,
        appointmentHour: pd.offer == "4hr" ? "4" : booking.appointmentHour,
        bookingdate: pd.offer == "wed" ? moment(pd.data).format("YYYY-MM-DD") : booking.bookingdate,
      })
      return data
    } catch (error) {
      handleApiError(error, openToast)
    }
  }
  const fetchBookingPriceDifference = async (po) => {
    try {
      const { data } = await api.post(`v1/booking/getBookingPriceDifference`, {
        pricingDtoOne: {
          productId: category.productId,
          technicianId: booking.technicianId,
          timeSlotId: booking.timeslotId,
          appointmentDate: moment(appointment.vDate).format("DD/MM/YYYY"),
          userId: booking.customer.id,
        },
        pricingDtoTwo: {
          productId: category.productId,
          technicianId: booking.technicianId,
          timeSlotId: po.offer == "4hr" ? po.data.id : booking.timeslotId,
          appointmentDate: po.offer == "wed" ? moment(po.data).format("DD/MM/YYYY") : moment(appointment.vDate).format("DD/MM/YYYY"),
          userId: booking.customer.id,
        },
      })
      return { ...po, ...data }
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  useEffect(() => {
    if (appointment.vHour && appointment.vDate && appointment.technicianId) {
      fetchTimeslot()
    }
  }, [appointment])

  const handleSubmit2 = async () => {
    try {
      let validationSchema = [
        { field: category.level1id, errMsg: "Issue required*" },
        { field: category.level2id, errMsg: "Issue required*" },
        { field: category.level3id, errMsg: "Issue required*" },
        { field: category.productId, errMsg: "Product required*" },
        { field: booking.customer.name, errMsg: "Name required*" },
        { field: booking.customer.mobile, errMsg: "Mobile required*" },
        { field: booking.customer.authemail, errMsg: "Email required*" },
        { field: booking.customer.authpassword, errMsg: "Password required*" },
        { field: booking.bookingdate, errMsg: "Appointment Date required*" },
      ]
      validationSchema.forEach((item) => {
        if (!item.field) {
          throw item.errMsg
        }
      })
      try {
        // let cat1id = allCategory.find(
        //   item => item.name == category.level1id && !item.parent
        // ).id
        // let cat2id = allCategory.find(
        //   item => item.name == category.level2id && item.parent == cat1id
        // ).id
        let cat3name = allCategory.find((item) => item.id == category.level3id).name
        let cat4name = products.find((item) => item.id == category.productId)

        const { data } = await api.post(`v1/booking`, {
          category1: category.level1id,
          category2: category.level2id,
          category3: cat3name,
          category4: cat4name.productnumber + " - " + cat4name.headertext,
          ...booking,
          files: uploadImgs,
          questions: questions,
        })
        openToast({
          color: "success",
          message: "Successfully Booked Appointment",
        })
      } catch (error) {
        handleApiError(error, openToast)
      }
    } catch (error) {
      openToast({
        color: "error",
        message: error,
      })
    }
  }

  const handleSubmit = async () => {
    try {
      let validationSchema = [
        { field: category.level1id, errMsg: "Issue required*" },
        { field: category.level2id, errMsg: "Issue required*" },
        { field: category.level3id, errMsg: "Issue required*" },
        { field: category.productId, errMsg: "Product required*" },
        { field: booking.bookingdate, errMsg: "Appointment Date required*" },
      ]
      validationSchema.forEach((item) => {
        if (!item.field) {
          throw item.errMsg
        }
      })
      try {
        let cat3name = allCategory.find((item) => item.id == category.level3id).name
        let cat4name = products.find((item) => item.id == category.productId)
        const { data } = await api.post(`v1/booking/pay`, {
          pricingDto: {
            productId: category.productId,
            technicianId: booking.technicianId,
            timeSlotId: booking.timeslotId,
            appointmentDate: moment(appointment.vDate).format("DD/MM/YYYY"),
            userId: booking.customer.id,
          },
          bookingModel: {
            category1: category.level1id,
            category2: category.level2id,
            category3: cat3name,
            category4: cat4name.productnumber + " - " + cat4name.headertext,
            ...booking,
            bookingdate: moment(booking.bookingdate).format("YYYY-MM-DD"),
            files: uploadImgs,
            questions: questions,
          },
        })
        window.location.href = data.paymentUrl
        // openToast({
        //     color: 'success',
        //     message: 'Successfully Booked Appointment'
        // })
      } catch (error) {
        handleApiError(error, openToast)
      }
    } catch (error) {
      openToast({
        color: "error",
        message: error,
      })
    }
  }

  useEffect(() => {
    let appStore = JSON.parse(getAppItem("_appStore", false))
    if (appStore) {
      setActiveStep(appStore.activeStep)
      setCompletedStep(appStore.completedStep)
      setCategory(appStore.category)
      setAllCategory(appStore.allCategory)
      setProducts(appStore.products)
      setQuestions(appStore.questions)
      setTimeslots(appStore.timeslots)
      setTechnicians(appStore.technicians)
      setBooking({ ...appStore.booking, customer: authUser })
      setUploadImgs(appStore.uploadImgs)
      setAppointment(appStore.appointment)
    }
  }, [])

  const saveAppStore = () => {
    setAppItem(
      "_appStore",
      JSON.stringify({
        activeStep: activeStep,
        completedStep: completedStep,
        category: category,
        allCategory: allCategory,
        products: products,
        questions: questions,
        timeslots: timeslots,
        technicians: technicians,
        booking: booking,
        uploadImgs: uploadImgs,
        appointment: appointment,
      })
    )
  }

  useEffect(() => {
    if (authUser) {
      saveAppStore()
    }
    if (activeStep > 3 && !authUser) {
      setActiveStep(1)
      setCompletedStep(1)
    }
    if (activeStep == 5) {
      fetchBookingPrice()
    }
  }, [activeStep])

  const handleLogout = () => {
    // setBooking(prev => ({ ...prev, customer: bookingSchema.customer }))
    // setBooking(bookingSchema)
    // setAuthUser()
    // setAppointment({ vHour: '', vDate: '' })
    removeAppItem("_appStore")
    removeAppItem("_authUser")
    // if (activeStep > 3) {
    //     setActiveStep(1)
    //     setCompletedStep(1)
    // }
    window.location.href = "/"
    openToast({
      color: "success",
      message: "Successfully Logged Out !!!",
    })
  }

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         saveAppStore()
  //     }, 5000);
  //     return () => clearInterval(interval);
  // }, [])

  const steps = [
    {
      id: 1,
      label: "Issue",
      component: (
        <Issue
          category={category}
          setCategory={setCategory}
          allCategory={allCategory}
          setAllCategory={setAllCategory}
          shopProducts={shopProducts}
          setShopProducts={setShopProducts}
          products={products}
          setProducts={setProducts}
          setTechnicians={setTechnicians}
          setQuestions={setQuestions}
          handleActiveStep={handleActiveStep}
        />
      ),
    },
    {
      id: 2,
      label: "Issue Details",
      component: (
        <IssueDetails
          questions={questions}
          setQuestions={setQuestions}
          uploadImgs={uploadImgs}
          setUploadImgs={setUploadImgs}
          handleMoreDetailsText={(e) => setBooking({ ...booking, modedetails: e.target.value })}
          handleActiveStep={handleActiveStep}
        />
      ),
    },
    {
      id: 3,
      label: "Your Details",
      component: (
        <UserDetails booking={booking} setBooking={setBooking} authUser={authUser} handleActiveStep={handleActiveStep} handleLogin={handleLogin} />
      ),
    },
    {
      id: 4,
      label: "Schedule",
      component: (
        <ScheduleTime
          authUser={authUser}
          category={category}
          appointment={appointment}
          setAppointment={setAppointment}
          technicians={technicians}
          setTechnicians={setTechnicians}
          booking={booking}
          setBooking={setBooking}
          timeslots={timeslots}
          handleActiveStep={handleActiveStep}
        />
      ),
    },
    {
      id: 5,
      label: "Confirm",
      component: (
        <ConfirmBooking
          authUser={authUser}
          booking={booking}
          category={category}
          handleSubmit={handleSubmit}
          fetchBookingPrice={fetchBookingPrice}
          fetchBookingPriceDifference={fetchBookingPriceDifference}
          allTimeslots={allTimeslots}
          appointment={appointment}
          fetchTimeslot={fetchTimeslot}
          handleActiveStep={handleActiveStep}
        />
      ),
    },
  ]

  const overlayPages = [
    { id: "my-account", component: <MyAccount handleOverlay={(id) => setActiveOverlay(id)} booking={booking} /> },
    { id: "shop-product", component: <ShopProducts shopProducts={shopProducts} setShopProducts={setShopProducts} /> },
  ]

  const modals = [
    {
      id: "login",
      component: (
        <LoginModal
          handleLogin={handleLogin}
          handleModalClose={(modal) => setAuthModal(modal ?? null)}
          handleOpenRegister={() => setAuthModal("register")}
        />
      ),
    },
    { id: "register", component: <RegisterModal handleRegister={handleRegister} handleModalClose={() => setAuthModal(null)} /> },
    {
      id: "forgot-password",
      component: <ForgotPasswordModal handleForgotPassword={handleForgotPassword} handleModalClose={() => setAuthModal(null)} />,
    },
    { id: "forgot-password?token=123", component: <RegisterModal handleRegister={handleRegister} handleModalClose={() => setAuthModal(null)} /> },
  ]

  useEffect(() => {
    if (shopProducts.open) setActiveOverlay("shop-product")
    else if (window.location.search.includes("payment=success")) {
      setActiveOverlay("my-account")
      setActiveStep(1)
      setCompletedStep(1)
    } else setActiveOverlay("")
  }, [shopProducts])

  return (
    <>
      <div>
        <div class="container">
          <div id="sidebar" class="col-md-3 col-lg-4">
            <div class="sidebar-title">
              <h3>Schedule</h3>
              <h4>Your Appointment</h4>
            </div>
            <div class="stepper" ref={stepperRef}>
              <ul class="nav nav-tabs">
                {steps.map((item, index) => (
                  <li key={index} className={stepStyle(item.id)} onClick={() => handleStepperClick(item.id)}>
                    <div>{item.label}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div class="logo">
              <a href="/">
                <img src="/images/igd-logo.png" alt="IGD Plumbing" style={{ width: "100%" }} />
              </a>
            </div>
          </div>
          <div class="col-md-9 col-lg-8" style={{ height: "100vh", position: "relative" }}>
            {/* <!-- Login & Button Dropdown --> */}
            {!authUser ? (
              <div class="dropdown">
                <button class="dropbtn" onClick={() => setAuthModal("login")}>
                  <span class="fa fa-user dp-icon"></span> Login{" "}
                </button>
              </div>
            ) : (
              <div class="dropdown">
                <button class="dropbtn">
                  {" "}
                  <span class="fa fa-user dp-icon"></span> {authUser?.name}
                </button>
                <div class="dropdown-content">
                  <a href="#" onClick={() => setActiveOverlay("my-account")}>
                    My Account
                  </a>
                  <a href="#" onClick={handleLogout}>
                    Log Out
                  </a>
                </div>
              </div>
            )}

            <div id="fullpage" class="row">
              <StoreContext.Provider value={{ openModal: (id) => setAuthModal(id) }}>
                {steps.find((item) => item.id == activeStep)?.component ?? <></>}
              </StoreContext.Provider>
            </div>
          </div>
        </div>
      </div>
      <div class={`overlay02 ${activeOverlay ? "is-open" : ""}`}>{overlayPages.find((item) => item.id == activeOverlay)?.component ?? <></>}</div>
      {modals.find((item) => item.id == authModal)?.component ?? <></>}
    </>
  )
}

export default StepperLayout
