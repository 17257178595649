import React, { useState, useEffect } from 'react'
import api, { toFormData } from '../../libs/api'
import { handleApiError } from '../../utils/handleApiError'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'

const Booking = props => {
    const [allBookings, setAllBookings] = useState([])
    let authUser = JSON.parse(localStorage.getItem('_authUser'))

    const fetchBookings = async values => {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${authUser.authToken}`;
            const { data } = await api.get(`v1/booking/myBookings`)
            setAllBookings(data)
            // openToast({ color: 'success', message: 'Successfull' })
        } catch (error) {
            // handleApiError(error, setToast)
            handleApiError(error, openToast)
        }
    }

    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }
    useEffect(() => {
        fetchBookings()
    }, [])
    return (
        <>
            <div class="booking-details  col-md-9">
                <div class="panel">
                    <div class="panel-body bio-graph-info">
                        <h1>Booking Details</h1>
                        <div class=" row">
                            <div class="table-responsive">
                                <table class="table table-hover" style={{ fontSize: '1.4em' }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Booking Date</th>
                                            <th>Timeslot</th>
                                            <th>Technician</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {allBookings.length ?
                                        <tbody id="items">
                                            {allBookings.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{moment(item.bookingdate, 'YYYY-MM-DD').format('MM-DD-YYYY')}</td>
                                                    <td>{item.timeslot}</td>
                                                    <td>{item.technicianName}</td>
                                                    <td>${Number(item.totalAmount).toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody> :
                                        <tbody id="items">
                                            <tr><td colSpan='5' style={{ textAlign: 'center', fontWeight: 'bold', padding: '5em 0' }}>
                                                No Records</td>
                                            </tr>
                                        </tbody>
                                        }
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Booking
