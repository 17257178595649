import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../libs/api"
import { handleApiError } from "../../utils/handleApiError"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import useLocalStorage from "../../utils/useLocalStorage"

const LoginModal = (props) => {
  const { handleLogin, handleModalClose, handleOpenRegister } = props
  const [showPass, setShowPass] = useState(false)
  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }
  return (
    <>
      <div class="modal-window">
        <div class="modal-content" style={{ width: "70vh" }}>
          <a href="#" class="fa fa-times close-btn modal-close" style={{ fontSize: "2em" }} onClick={handleModalClose}></a>
          <div>
            <p class="modal-title" style={{ fontSize: "3em", textAlign: "center", fontWeight: "bold" }}>
              Login
            </p>
            <Formik
              initialValues={{
                authemail: "",
                authpassword: "",
              }}
              validationSchema={Yup.object({
                authemail: Yup.string().email("Invalid email address").required("Required"),
                authpassword: Yup.string().required("Required"),
              })}
              onSubmit={handleLogin}>
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <div class="reg-form" style={{ maxWidth: "100%", padding: "1em 3em 2em 0" }}>
                      <div class="form-field">
                        <div class="field">
                          {/* <label for='emailaddress'>Email *</label> */}
                          <Field
                            name="authemail"
                            type="text"
                            placeholder="Email"
                            style={{ fontSize: "2em", borderRadius: "1.5em", paddingLeft: "1em", width: "100%" }}
                          />
                        </div>
                      </div>

                      <div class="form-field">
                        <div class="field position-relative">
                          {/* <label for='password'>Password *</label> */}
                          <Field
                            name="authpassword"
                            placeholder="Password"
                            style={{ fontSize: "2em", borderRadius: "1.5em", paddingLeft: "1em", width: "100%" }}
                            type={showPass ? "text" : "password"}
                          />
                          <div className="passEyeIcon" style={{ top: 0, right: "20px" }}>
                            <i className={`fa ${showPass ? "fa-eye" : "fa-eye-slash"} h4`} role="button" onClick={() => setShowPass(!showPass)}></i>
                          </div>
                          {/* {errors["authpassword"] && <small style={{ color: "red", fontSize: ".6em" }}>{errors["authpassword"]}</small>} */}
                        </div>
                        <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                          <div
                            role="button"
                            style={{ fontSize: "1.5em", margin: ".5em 2em 0 0 " }}
                            onClick={() => handleModalClose("forgot-password")}>
                            Forgot Password ?
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3em" }}>
                      <button
                        type="submit"
                        style={{
                          fontSize: "2em",
                          backgroundColor: "#315698",
                          color: "white",
                          borderRadius: "1em",
                          padding: ".2em 1.5em",
                          border: "none",
                        }}
                        // onClick={handleModalClose}
                      >
                        Login
                      </button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2em", fontSize: "1.6em" }}>
                      Not Registered ?{" "}
                      <span style={{ color: "#315698", marginLeft: ".5em", cursor: "pointer", fontWeight: "bold" }} onClick={handleOpenRegister}>
                        Create Account
                      </span>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div></div>
        </div>
      </div>
    </>
  )
}

export default LoginModal
