import React, { useState, useEffect, } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import api, { toFormData } from '../../../libs/api'
import { handleApiError } from '../../../utils/handleApiError'

const Questions = (props) => {
    const { questions, setQuestions, handleIssueDetailsStep } = props
    const continueActiveBtn = questions.find(q => q.answers) ? 'button continuebtn-active' : 'button continuebtn'

    const openToast = ({ color, message }) => {
        toast(message, {
            type:
                color == 'success'
                    ? toast.TYPE.SUCCESS
                    : color == 'error'
                        ? toast.TYPE.ERROR
                        : toast.TYPE.INFO,
            className: 'font-size-lg display-1'
        })
    }

    const handleQuestionSubmit = async () => {
        try {
            let qAns = questions.find(q => q.answers)
            let validationSchema = [
                { field: qAns, errMsg: 'Selection required*' },
            ]
            validationSchema.forEach(item => {
                if (!item.field) {
                    throw item.errMsg
                }
            })
            handleIssueDetailsStep(2)
        } catch (error) {
            openToast({
                color: 'error',
                message: error
            })
        }
    }
    return <>
        {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
        <div class='section' id='section1'>
            <div class='content'>
                <p class='section-title'>
                    <a onClick={() => handleIssueDetailsStep(0)}>
                        <span class='fa fa-arrow-left'></span>
                    </a>{' '}
                    Tell Us More
                </p>
                <div className='multiQueContent'>
                    {questions.map((qItem, qIndex) => (
                        <div class='form-field' key={qIndex}>
                            <p class='issue-title'>{qItem.questiontext}</p>
                            {qItem.options.map((oItem, oIndex) => (
                                <div key={`${qIndex}-${oIndex}`}
                                    class={
                                        qItem.answers == oItem ? 'pill selected' : 'pill'
                                    }
                                    onClick={() => {
                                        setQuestions(
                                            questions.map((aItem, aIndex) =>
                                                qIndex == aIndex
                                                    ? { ...aItem, answers: oItem }
                                                    : aItem
                                            )
                                        )
                                    }}
                                >
                                    {oItem}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <a class={continueActiveBtn} onClick={handleQuestionSubmit}>
                    Continue <span class='fa fa-arrow-circle-o-right'></span>
                </a>
            </div>
        </div>
    </>;
}

export default Questions;