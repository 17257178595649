import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import ConfirmBooking2 from "./ConfirmBooking2"
import ConfirmBooking1 from "./ConfirmBooking1"

const ConfirmBooking = (props) => {
  const {
    authUser,
    booking,
    category,
    fetchBookingPrice,
    allTimeslots,
    appointment,
    setAppointment,
    fetchTimeslot,
    fetchBookingPriceDifference,
    handleSubmit,
    handleActiveStep,
  } = props
  const [bookingStep, setBookingStep] = useState(1)

  const handleBookingStep = (s) => {
    setBookingStep(s)
  }

  const bookingComp = [
    { id: 1, component: <ConfirmBooking1 authUser={authUser} booking={booking} category={category} handleBookingStep={handleBookingStep} /> },
    {
      id: 2,
      component: (
        <ConfirmBooking2
          booking={booking}
          category={category}
          fetchBookingPrice={fetchBookingPrice}
          fetchBookingPriceDifference={fetchBookingPriceDifference}
          allTimeslots={allTimeslots}
          appointment={appointment}
          setAppointment={setAppointment}
          fetchTimeslot={fetchTimeslot}
          handleSubmit={handleSubmit}
          handleBookingStep={handleBookingStep}
        />
      ),
    },
  ]

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  useEffect(() => {
    if (bookingStep == 0) {
      handleActiveStep(2)
      setBookingStep(1)
    } else if (bookingStep == 4) {
      handleActiveStep(4)
      setBookingStep(1)
    }
  }, [bookingStep])

  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      {bookingComp.find((item) => item.id == bookingStep)?.component ?? <></>}
    </>
  )
}

export default ConfirmBooking
