import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

import moment from "moment"

import gbgImg from "../../../assets/images/garbage.jpeg"
import trashIcon from "../../../assets/images/trash.svg"

const ConfirmBooking2 = (props) => {
  const {
    booking,
    category,
    handleSubmit,
    allTimeslots,
    appointment,
    setAppointment,
    fetchTimeslot,
    handleBookingStep,
    fetchBookingPrice,
    fetchBookingPriceDifference,
  } = props
  const [modalOpen, setModalOpen] = useState(0)
  const [price, setPrice] = useState({
    subTotalPrice: 0,
    discount: 0,
    totalPrice: 0,
    activeSubscription: false,
  })
  // const [hour4Timeslot, setHour4Timeslot] = useState()
  const [priceOffer, setPriceOffer] = useState({ offer: "", data: "" })
  const [priceDiff, setPriceDiff] = useState({ offer: "", savedAmount: null })

  const handleModalClose = () => {
    setModalOpen(0)
  }

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  useEffect(async () => {
    let p = await fetchBookingPrice()
    setPrice(p)
  }, [])

  // useEffect(async () => {
  //     let d = await fetchBookingPriceDifference(hour4Timeslot)
  //     setPriceDiff(d)
  // }, [hour4Timeslot])

  // useEffect(async () => {
  //   let d = await fetchBookingPriceDifference(priceOffer)
  //   setPriceDiff(d)
  // }, [priceOffer])

  if (!price.totalPrice) {
    return (
      <div class="section" id="section9">
        <div class="content confirm">
          <p class="section-title B TT">
            <a onClick={() => handleBookingStep(1)}>
              <span class="fa fa-arrow-left"></span>
            </a>{" "}
            Confirm Your Booking{" "}
          </p>
          <p class="section-title" style={{ fontWeight: "normal", paddingLeft: "1em" }}>
            Loading...
          </p>
        </div>
      </div>
    )
  }
  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      <div class="section" id="section9">
        <div class="content confirm">
          <p class="section-title B TT">
            <a onClick={() => handleBookingStep(1)}>
              <span class="fa fa-arrow-left"></span>
            </a>{" "}
            Confirm Your Booking{" "}
          </p>
          <div class="booking-confirm-2">
            <div class="row-1">
              <div>Issue Description</div>
              <div>Price</div>
            </div>
            {/* <div class="row-2">
                        <div>
                            <div>Plumbing Replacement</div>
                            <div>Garbage Disposal</div>
                            <div style={{ display: 'flex' }}>
                                <div class="prod-img"><img src={gbgImg} /></div>
                                <div class="prod-details">INSINKERATOR Evolution Compact 3/4 HP <br />Continuos Feed Garbage Disposal</div>
                              </div>
                        </div>
                        <div>$495</div>
                    </div> */}
            <div class="row-2">
              <div>
                <div>
                  {category.level1id} {category.level2id}
                </div>
                <div>{category.product.headertext}</div>
                <div style={{ display: "flex" }}>
                  <div class="prod-img">
                    <img src={category.product.files?.[0]?.valueUrl} />
                  </div>
                  <div class="prod-details">
                    {" "}
                    {category.product.productnumber} - {category.product.headertext} <br />
                    {category.product.subheadertext}
                  </div>
                </div>
              </div>
              <div>${Number(price.subTotalPrice ?? 0).toFixed(2)}</div>
            </div>
            <div class="row-3"></div>

            <div class="row-4">
              <div style={{ backgroundColor: "white", width: "13em" }}>
                <span className="text-muted">Booking Date:</span>
                <span style={{ fontWeight: "bold" }}>{moment(booking.bookingdate).format("MM-DD-YYYY")}</span>
              </div>
              <div>
                <div>Subtotal:</div>
                <div>${Number(price.subTotalPrice ?? 0).toFixed(2)}</div>
              </div>
            </div>
            <div class="row-5">
              {booking.appointmentHour != "4" && (
                <>
                  {priceDiff.offer == "4hr" && priceDiff.savedAmount != 0 ? (
                    <div>
                      <div>
                        <div role="button" onClick={() => setModalOpen(1)}>
                          Increase to 4 hour appointment window and
                          <span> SAVE ${Math.abs(Number(priceDiff.savedAmount).toFixed(2))}</span>
                        </div>
                        <div style={{ display: "block" }}>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              onClick={async () => {
                                let p = await fetchBookingPrice(priceDiff)
                                setPrice(p)
                              }}>
                              Apply Offer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      role="button"
                      onClick={async () => {
                        await fetchTimeslot({ ...appointment, vHour: "4" })
                        setModalOpen(1)
                      }}>
                      <div>
                        Increase to 4 hour appointment window and <span>SAVE MORE</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {moment(booking.bookingdate).day() && !booking.wedOfferApplied ? ( // != "3"
                <div>
                  {priceDiff.offer == "wed" ? (
                    <div>
                      <div onClick={() => setModalOpen(2)}>
                        Book an appointment on {moment(price.nextWednesday).format("dddd, MMMM Do")}
                        <span> SAVE ${Math.abs(Number(priceDiff.savedAmount).toFixed(2))}</span>
                      </div>
                      <div style={{ display: "block" }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <button
                            onClick={async () => {
                              let p = await fetchBookingPrice(priceDiff)
                              setPrice({
                                subTotalPrice: p.subTotalPrice,
                                discount: p.discount,
                                totalPrice: p.totalPrice,
                                activeSubscription: p.activeSubscription,
                              })
                            }}>
                            Apply Offer
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div role="button" onClick={() => setModalOpen(2)}>
                      <div>
                        Book an appointment on {moment(price.nextWednesday).format("dddd, MMMM Do")} and
                        <span> SAVE MORE</span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
              {!price.activeSubscription && (
                <div onClick={() => setModalOpen(3)}>
                  <div>
                    Become IGD Plumbing and Air Member and
                    <span> SAVE 20%</span>
                  </div>
                </div>
              )}
            </div>
            {price.discount ? (
              <div class="row-6">
                <div>
                  <div>Discount ({price.discountPercent}):</div>
                  <div>${Number(price.discount ?? 0).toFixed(2)}</div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div class="row-7">
              <div>
                <div>Total:</div>
                <div>${Number(price.totalPrice ?? 0).toFixed(2)}</div>
              </div>
            </div>
            <div className="row-8" style={{ float: "right", fontSize: ".8em", marginRight: "1em",width:"28%" }}>
              <a role="button" onClick={() => setModalOpen(5)}>
                {" "}
                
                 Become IGD Plumbing and Air Member or IGD Smart Club
              </a>
            </div>
          </div>

          {modalOpen ? (
            <div class="modal-window">
              {modalOpen == 1 ? (
                <div class="modal-content">
                  <a role="button" class="fa fa-times close-btn modal-close" onClick={handleModalClose}></a>
                  {/* <p class="modal-title">Change Your Appointment Time
                                    <i class='fa fa-info'></i>
                                </p>
                                <div class='form-select' style={{ margin: '0' }}>
                                    <select
                                        name='appointmentWindow'
                                        // onChange={e => {
                                        //     setAppointment({
                                        //         ...appointment,
                                        //         vHour: e.target.value
                                        //     })
                                        // }}
                                        style={{ border: 'solid grey 1.5px', padding: '.5em 1em' }}
                                    >
                                        <option>Select your appointment window</option>
                                        <option value='1'>1 hour</option>
                                        <option value='2'>2 hours</option>
                                        <option value='3'>3 hours</option>
                                        <option value='4'>4 hours</option>
                                    </select>
                                </div> */}
                  <div>
                    <div class="form-field">
                      {/* <p class='modal-title'>Tuesday, October 3</p> */}
                      <p class="modal-title" style={{ fontSize: "1em" }}>
                        Select your 4 hour appointment window on <br />
                        {moment(booking.bookingdate).format("dddd, MMMM Do YYYY")}
                      </p>
                      {allTimeslots
                        // ?.filter((item) => item.timeSlot == "4")
                        .map((item) => (
                          <>
                            <div
                              // class={hour4Timeslot?.id == item.id ? 'pill selected' : 'pill'}
                              class={priceOffer.data?.id == item.id ? "pill selected" : "pill"}
                              style={{ padding: "2% 4%" }}
                              onClick={async () => {
                                setPriceOffer({ offer: "4hr", data: item })
                                let p = await fetchBookingPriceDifference({ offer: "4hr", data: item })
                                setPriceDiff(p)
                              }}>
                              {item.description}
                            </div>
                          </>
                        ))}
                      {priceDiff.savedAmount == 0 && (
                        <p style={{ color: "red", margin: "10px 0px" }}>
                          Discount amount is $0.
                          <br />
                          Please select different timeslot.{" "}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="modal-actions">
                    <div>
                      <button
                        onClick={handleModalClose}
                        disabled={!priceDiff.savedAmount}
                        style={!priceDiff.savedAmount ? { backgroundColor: "lightgrey" } : {}}>
                        Save
                      </button>
                    </div>
                    {/* <div
                      onClick={() => {
                        setPriceOffer({ offer: "", data: "" })
                        handleModalClose()
                      }}>
                      <span style={{ textDecorationLine: "underline", color: "red", paddingRight: ".4em" }}>Remove Offer</span>
                      <img src={trashIcon} />
                    </div> */}
                  </div>
                </div>
              ) : modalOpen == 2 ? (
                <div class="modal-content">
                  <a href="#" class="fa fa-times close-btn modal-close" onClick={handleModalClose}></a>
                  <p class="modal-title">
                    Change Appointment To
                    <i class="fa fa-info"></i>
                  </p>
                  <div>
                    <div class="form-field">
                      <p class="modal-title" style={{ fontWeight: "bold", fontSize: "1em" }}>
                        {moment(price.nextWednesday).format("dddd, MMMM Do YYYY")}
                      </p>
                    </div>
                  </div>
                  <div class="modal-actions">
                    <div>
                      <button
                        onClick={async () => {
                          setPriceOffer({ offer: "wed", data: price.nextWednesday })
                          let p = await fetchBookingPriceDifference({ offer: "wed", data: price.nextWednesday })
                          setPriceDiff(p)
                          handleModalClose()
                        }}>
                        Save
                      </button>
                    </div>
                    {/* <div
                      onClick={() => {
                        setPriceOffer({ offer: "", data: "" })
                        handleModalClose()
                      }}>
                      <span style={{ textDecorationLine: "underline", color: "red", paddingRight: ".4em" }}>Remove Offer</span>
                      <img src={trashIcon} />
                    </div> */}
                  </div>
                </div>
              ) : modalOpen == 3 ? (
                <>
                  <div class="modal-content">
                    <a href="#" class="fa fa-times close-btn modal-close" onClick={handleModalClose}></a>
                    <div class="modal-title" style={{ marginBotton: "0px" }}>
                      Become a Member
                      <i class="fa fa-info"></i>
                    </div>
                    <div style={{ fontSize: ".6em" }}>Become a member and enjoy 20% off on all the plumbing and HVAC services.</div>
                    <div class="modal-title" style={{ color: "black", fontSize: ".7em", marginTop: "1em" }}>
                      {/* Membership Monthly Price $16
                                                <div style={{ fontStyle: 'italic', fontSize: '.9em' }}>Recurring Annually</div> */}
                    </div>
                    <div class="modal-actions">
                      <div>
                        <button onClick={() => setModalOpen(4)}>JOIN MEMBERSHIP</button>
                      </div>
                      {/* <div onClick={handleModalClose}>
                        <span style={{ textDecorationLine: "underline", color: "red", paddingRight: ".4em" }}>Remove Offer</span>
                        <img src={trashIcon} />
                      </div> */}
                    </div>
                  </div>
                </>
              ) : modalOpen == 4 ? (
                <>
                  <div class="modal-content" style={{ width: "80%", padding: ".1em" }}>
                    <a href="#" class="fa fa-times close-btn modal-close" style={{ backgroundColor: "white" }} onClick={handleModalClose}></a>
                    <div
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: "20px",
                        overflow: "hidden",
                        padding: "1em 0em",
                        backgroundColor: "#efefef",
                      }}>
                      <stripe-pricing-table pricing-table-id="prctbl_1P98trGVgEYPRthKI7tFaujL"
                        publishable-key="pk_live_51MqJm7GVgEYPRthKXOrDiEia85tZyCMa5rqFOpsLZjCumhrFTE6cfIHuEfCIrm6BjjlTAA4qbQPYfX80bZgvvh2b00VVWD46ju">
                      </stripe-pricing-table>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div class="modal-content">
                    <a href="#" class="fa fa-times close-btn modal-close" onClick={handleModalClose}></a>
                    <div class="modal-title" style={{ marginBotton: "0px" }}>
                      Don’t like the total price?
                    </div>
                    <div class="modal-title" style={{ color: "black", fontSize: ".7em", marginTop: "1em" }}>
                      <div>
                        <p>
                          Move your appointment to a further future date (at least 3 business days from today), use the discounts above, and choose an
                          appointment window that starts between 10am – 4pm on Monday through Friday.
                        </p>

                        <p>
                          {" "}
                          If you have chosen a time that does not fall between 10am and 4pm on Monday – Friday, you may have chosen a highly desirable
                          appointment time or even an Emergency appointment window time. If you would like to save more, select a time that starts
                          between 10am and 4pm on a Monday – Friday. Saturday and Sunday appointments are considered desirable and emergency
                          appointments, which leads to a hire total price.
                        </p>
                       <center> <h3>IGD SMART CLUB</h3></center>
                        <p>
                          Stay comfortable all year with our IGD SMART CLUB MEMBERSHIP. The SmartAC.com sensors help detect and prevent costly HVAC and plumbing problems, ensuring worry-free performance for your heating, air conditioning, and water heater systems!
                        </p>
                        {/* here plz */}
                        <div style={{ border: " 1px solid black", padding: "10px", margin: "10px 0", color: "black" }}>
                          <h4>Benefits</h4>
                          <ul style={{ margin: "0px", paddingLeft: "20px", listStyleType: "disc" }}>
                            <li>24/7 Monitoring for HVAC</li>
                            <li><strong>Free Sensor Installation:</strong> Includes free installation of up to three sensors per AC appliance: water sensor, filter sensor, and comfort sensor. Additional sensors for a second unit, water heater leak detection, or pipe leak detection are available for purchase upon request.</li>
                            <li><strong>Priority Emergency Service</strong></li>
                            <li>20% Off Repairs</li>
                            <li>1-Year Repair Warranty (it is valid for only active members)</li>
                            <li>One Complimentary Lateral drain cleaning per year /as needed/</li>
                            <li>One Complimentary Chemical Water Quality Analysis /as needed/</li>
                            <li>One Annual AC-Heating and Plumbing Inspection</li>
                          </ul>
                          <p><strong>Monthly:</strong> $24.99</p>
                          <p><strong>Yearly:</strong> $249.00</p>
                        </div>

                        <center> <h3>Exclusions & Terms</h3></center>
                        {/* <h4>Exclusions & Terms</h4> */}
                        <hr style={{ border: "1px solid black", margin: "20px 0" }} />
                        <ul style={{ margin: "0px", paddingLeft: "20px", listStyleType: "disc" }}>
                          <li>Drain clearings are excluded if there is not a ground level accessible clean out.</li>
                          <li>Any recommended repairs while performing maintenance visits are excluded.</li>
                          <li>Additional sensors available for purchase.</li>
                          <li>HVAC system(s) must be operational to perform regularly scheduled maintenance.</li>
                          <li>Tankless water heater flushes are not included in the program benefits.</li>
                          <li>Emergency Service: No cooling, no heating, no water, or flooding.</li>
                          <li>Anode rods for tank type water heaters are not included.</li>
                          <li>Supply of air filters are not included in the program benefits.</li>
                        </ul>
                        <p style={{ fontSize: "12px" }}>
                          <strong>Equipment purchases:</strong> AC and heating equipment, duct replacement, water heater, water purity, drain and pipe replacement.
                        </p>
                        <p style={{ fontSize: "12px" }}>
                          <strong>Disclaimer:</strong>
                          The customer is responsible for any additional service work provided outside the scope of the HVAC and plumbing plan. The condition of the HVAC and plumbing system will be reported at the maintenance service but does not imply any warranties on performance in the future. Customer must have an accessible cleanout at ground level. Roof access is excluded. In the event a drain cannot be cleared with a cable, hydro jetting may be necessary to unclog the stoppage at an additional cost. The member is limited to 1 stoppage per year and does not cover abuse, foreign objects, roots, broken or collapsed lines. Any other access than an approved ground level cleanout will be an additional cost. Cannot be combined with any other additional offers, promotions, or coupons. The club membership is non-refundable if the first visit has been performed or the amount of the discounts you received on your repairs have exceeded the price of the membership you purchased. Your purchase of this membership through our online platform serves as a signature and is approving the estimate and the acknowledgement of services rendered will serve as your approval of this service contract.
                        </p>
                        <p style={{ fontSize: "12px" }}>
                          <strong>Third-Party Data Collection Policy:</strong>
                          The membership may include services, content, data, information, applications, web sites, mobile applications, tools, hardware, merchandise, and/or other materials generated or provided to you by third parties including, without limitation SmartAC.com, Inc.. Your access to and use of all Third Party Material may be subject to additional terms and conditions made available to you by such third parties, and by enrolling in the membership or using the Third Party Material you agree to all such additional terms and conditions. We make no representations or warranties with respect to any Third Party Material, and all representations or warranties, if any, related to the Third Party Material are provided by such third parties. Without limiting the foregoing, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the Third Party Material. In no event shall we be liable for any liabilities, losses, or damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of your use or inability to use the Third Party Material, even if we or our suppliers, licensors, or partners have been notified orally or in writing of the possibility of such liabilities, losses, or damages. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. To the extent any data or information is collected from you or your property or HVAC equipment as a result of your use of the membership or any Third Party Material, we may use and share such data and information for any lawful purpose, provided that, except to provide our services, the membership, or the Third Party Material, or as authorized by our suppliers, licensors, or partners, will not disclose any of your personally identifiable information to any third party.
                        </p>



                      </div>

                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}

          <a
            class="button continuebtn"
            style={{ backgroundColor: "#53CBCA", marginTop: "5em" }}
            onClick={() => {
              // setModalOpen(4)
              handleSubmit()
            }}>
            Book Now <span class="fa fa-arrow-circle-o-right"></span>
          </a>
        </div>
      </div>
    </>
  )
}

export default ConfirmBooking2
