export const handleApiError = (error, openSnackbar) => {
  if (error.response) {
    // Request made and server responded
    // console.log('Error Response', error.response)
    if (error.response.data) {
      if (error.response.data.errors) {
        openSnackbar({
          // open: true,
          color: "error",
          message: error.response.data.errors[0],
        })
      } else {
        openSnackbar({
          // open: true,
          color: "error",
          message: error.response.data,
        })
      }
    } else {
      openSnackbar({
        // open: true,
        color: "error",
        message: "Error " + error.response.status,
      })
    }
  } else if (error.request) {
    // The request was made but no response was received
    // console.log('Req' + error.request)
    openSnackbar({
      // open: true,
      color: "error",
      message: "Error",
    })
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message)
    openSnackbar({
      // open: true,
      color: "error",
      message: "Error",
    })
  }
}
