import axios from "axios"

const api = axios.create({
  baseURL: "https://api.igdplumbing.com/",
})

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (
      !localStorage.getItem("authToken") &&
      localStorage.getItem("authToken") === null &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup"
    ) {
      //   window.location.href = "/login";
      // config.headers['Authorization'] =
      // 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MWZjNTNmZGRmMjdhNjYxYTdmODI0OWEiLCJpc3MiOiJkNThiYmU4OC1kODVkLTRhOWQtOGFiNS1hZDcyNTZkYWM0NjQiLCJleHAiOjE2NDUyMjI3MjEsInR5cGUiOiJzeXN0ZW1AaWRncGx1bWJpbmcuY29tIiwiaWF0IjoxNjQ1MTkzOTIxfQ.Ubh2TZfj0twB68RhI0MrclTeBMlFYq8GNCWo2GsW7K8'
    } else if (
      // window.location.pathname === "/plumbing"
      // ||
      window.location.pathname.includes("/")
    ) {
      // config.headers['Authorization'] = localStorage.getItem('authToken');
    } else {
      // config.headers["Authorization"] = localStorage.getItem("authToken");
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 401 || error.response.status == 402 || error.response.status == 500) {
      localStorage.clear()
      // window.location.href = '/logout'
    }
    return Promise.reject(error)
  }
)

export const toFormData = (object) => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key])
  })
  return formData
}

export default api
