import React, { useState, useEffect, useContext } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { StoreContext } from "../../StepperLayout"

const LoginUser = (props) => {
  const { booking, handleLogin, handleUserDetailsStep } = props
  const { openModal } = useContext(StoreContext)
  const [showPass, setShowPass] = useState(false)

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      <div class="section" id="section5">
        <Formik
          initialValues={{
            authemail: "",
            authpassword: "",
          }}
          validationSchema={Yup.object({
            authemail: Yup.string().email("Invalid email address").required("Required"),
            authpassword: Yup.string().required("Required"),
          })}
          onSubmit={handleLogin}>
          {({ values, setFieldValue, ...rest }) => {
            return (
              <Form>
                <div class="content">
                  <p class="section-title B TT">
                    <a onClick={() => handleUserDetailsStep(1)}>
                      <span class="fa fa-arrow-left"></span>
                    </a>{" "}
                    Login
                  </p>
                  <div class="reg-form">
                    {/* <div class='form-field'>
                                <div class='field half'>
                                  <label for='fullname'>Name *</label>
                                  <Field name='name' type='text' />
                                </div>
                                <div class='field half last'>
                                  <label for='mobilenumber'>
                                    Mobile Number*
                                  </label>
                                  <Field name='mobile' type='text' />
                                </div>
                              </div> */}

                    <div class="form-field">
                      <div class="field">
                        <label for="emailaddress">Email *</label>
                        <Field name="authemail" type="text" />
                        {/* <input
                              type='email'
                              id='emailaddress'
                              name='emailaddress'
                              value=''
                            /> */}
                      </div>
                    </div>

                    <div class="form-field">
                      <div class="field position-relative">
                        <label for="password">Password *</label>
                        <Field
                          name="authpassword"
                          type={showPass ? "text" : "password"}
                        />
                        <div className="passEyeIcon" style={{top:'23px'}}>
                          <i className={`fa ${showPass ? "fa-eye" : "fa-eye-slash"} h4`} role="button" onClick={() => setShowPass(!showPass)}></i>
                        </div>
                        {/* <input
                              type='password'
                              id='password'
                              name='password'
                              value=''
                            /> */}
                      </div>
                      <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                        <div role="button" style={{ fontSize: ".7em", margin: ".7em 0 0 0 " }} onClick={() => openModal("forgot-password")}>
                          Forgot Password ?
                        </div>
                      </div>
                    </div>
                    <button
                      class={!(rest.isValid && rest.dirty) ? "button continuebtn" : "button continuebtn-active"}
                      type="submit"
                      style={{ border: "0" }}>
                      Continue <span class="fa fa-arrow-circle-o-right"></span>
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default LoginUser
