import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import api, { toFormData } from "../../../libs/api"
import { handleApiError } from "../../../utils/handleApiError"
import ChooseLogin from "./ChooseLogin"
import CreateAccount from "./CreateAccount"
import LoginUser from "./LoginUser"

const UserDetails = (props) => {
  const { booking, setBooking, authUser, handleActiveStep, handleLogin } = props
  const [userDetailsStep, setUserDetailsStep] = useState(1)

  const handleUserDetailsStep = (s) => {
    setUserDetailsStep(s)
  }

  const handleAccountSubmit = async (values) => {
    try {
      const { data } = await api.post(`v1/auth/customer/signup`, values)
      setBooking({ ...booking, customer: data })
      handleUserDetailsStep(3)
      openToast({ color: "success", message: "Signup Successful" })
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openToast)
    }
  }

  const handleLoginSubmit = async (values) => {
    try {
      await handleLogin(values)
    } catch (error) {
      handleApiError(error, openToast)
    }
  }

  const userDetailsComp = [
    { id: 1, component: <ChooseLogin booking={booking} setBooking={setBooking} handleUserDetailsStep={handleUserDetailsStep} /> },
    {
      id: 2,
      component: (
        <CreateAccount
          booking={booking}
          setBooking={setBooking}
          handleAccountSubmit={handleAccountSubmit}
          handleUserDetailsStep={handleUserDetailsStep}
        />
      ),
    },
    { id: 3, component: <LoginUser booking={booking} handleLogin={handleLoginSubmit} handleUserDetailsStep={handleUserDetailsStep} /> },
  ]

  const openToast = ({ color, message }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      className: "font-size-lg display-1",
    })
  }

  useEffect(() => {
    if (userDetailsStep == 0) {
      handleActiveStep(2)
      setUserDetailsStep(1)
    } else if (userDetailsStep == 4) {
      handleActiveStep(4)
      setUserDetailsStep(1)
    }
  }, [userDetailsStep])

  useEffect(() => {
    if (authUser) {
      setUserDetailsStep(4)
    }
  }, [])

  return (
    <>
      {/* <ToastContainer
            position='bottom-right'
            theme='colored'
            style={{ fontSize: '15px' }}
        /> */}
      {userDetailsComp.find((item) => item.id == userDetailsStep)?.component ?? <></>}
    </>
  )
}

export default UserDetails
